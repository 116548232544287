import { shapes, dia } from "jointjs";
import defaultsDeep from "lodash.defaultsdeep";
import markup from "./markup.svg";

shapes.custom.Enum = shapes.basic.Generic.extend({
  defaults: defaultsDeep(
    {
      type: "custom.Enum",
      markup: markup,

      attrs: {
        rect: { width: 100 },

        ".uml-class-name-rect": {
          stroke: "black",
          "stroke-width": 2,
          fill: "#fdc886",
        },
        ".uml-class-values-rect": {
          stroke: "black",
          "stroke-width": 2,
          fill: "#fff",
        },

        ".uml-class-name-text": {
          ref: ".uml-class-name-rect",
          "ref-y": 0.5,
          "ref-x": 0.5,
          "text-anchor": "middle",
          "y-alignment": "middle",
          "font-weight": "bold",
          fill: "black",
          "font-size": 12,
          "font-family": "Times New Roman",
        },
        ".uml-class-values-text": {
          ref: ".uml-class-values-rect",
          "ref-y": 5,
          "ref-x": 5,
          fill: "black",
          "font-size": 12,
          "font-family": "Times New Roman",
        },
      },

      name: [],
      values: [],
    },
    shapes.basic.Generic.prototype.defaults
  ),

  initialize: function () {
    this.on(
      "change:name change:values",
      function () {
        this.updateRectangles();
        this.trigger("uml-update");
      },
      this
    );

    this.updateRectangles();
    shapes.basic.Generic.prototype.initialize.apply(this, arguments);
  },

  editable: function () {},

  getName: function () {
    return this.get("name");
  },

  setName: function (newName) {
    this.set("name", newName);
  },

  getValues: function () {
    return this.get("values");
  },

  setValues: function (newValues) {
    this.set("values", newValues);
  },

  getId: function () {
    return this.get("id");
  },

  updateRectangles: function () {
    const attrs = this.get("attrs");

    const rects = [
      { type: "name", text: ["<<enum>>", this.getName()] },
      { type: "values", text: this.getValues() },
    ];

    let width = this.getName().length * 10;
    this.getValues().forEach(function (p) {
      width = Math.max(width, p.length * 10);
    });

    attrs[".uml-class-name-rect"].width = width;
    attrs[".uml-class-values-rect"].width = width;

    let offsetY = 0;

    rects.forEach(function (rect) {
      var lines = Array.isArray(rect.text) ? rect.text : [rect.text];
      var rectHeight = lines.length * 13 + 10;

      attrs[".uml-class-" + rect.type + "-text"].text = lines.join("\n");
      attrs[".uml-class-" + rect.type + "-rect"].height = rectHeight;
      attrs[".uml-class-" + rect.type + "-rect"].transform =
        "translate(0," + offsetY + ")";

      offsetY += rectHeight;
    });
  },
});

shapes.custom.EnumView = dia.ElementView.extend({
  initialize: function () {
    dia.ElementView.prototype.initialize.apply(this, arguments);

    this.listenTo(this.model, "uml-update", function () {
      this.update();
      this.resize();
    });
  },
});
