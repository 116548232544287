<template>
  <div>
    <nav-bar>
      <template v-slot:title>
        <v-container class="pl-0" fluid>
          <span class="font-italic">
            {{ engineStore.basicData.name }} (v.
            {{ engineStore.basicData.version }}) -
          </span>
          <span class="font-weight-thin">
            {{ " " + $t("menu.parametrization_stage") }}
          </span>
        </v-container>
      </template>

      <template v-slot:items>
        <v-btn :to="{ name: 'CustomizationExporter' }" text exact>
          {{ $tc("project.name", 1) }}
        </v-btn>
        <v-btn
          v-if="formsSelected"
          :to="{ name: 'FormCustomization_List' }"
          text
          exact
        >
          {{ $tc("custom.form.name", 2) }}
        </v-btn>
        <v-btn
          v-if="listsSelected"
          :to="{ name: 'ListCustomization_List' }"
          text
          exact
        >
          {{ $tc("custom.list.name", 2) }}
        </v-btn>
      </template>
    </nav-bar>

    <router-view></router-view>
  </div>
</template>

<script>
import engineStore from "@/services/engineStore";
import Project from "@/services/project.service";
import customization from "@/services/customization.service";
import NavBar from "@/components/common/NavBar";

export default {
  components: { NavBar },
  data() {
    return { engineStore };
  },
  computed: {
    listsSelected() {
      return this.engineStore.selection.indexOf("GUI_Lists") != -1;
    },
    formsSelected() {
      return this.engineStore.selection.indexOf("GUI_Forms") != -1;
    },
  },
  async beforeRouteEnter(to, from, next) {
    if (
      engineStore.basicData.name == to.params.pName &&
      engineStore.basicData.version == to.params.pVersion &&
      customization.customizing
    ) {
      next();
      return;
    }
    try {
      engineStore.load(await Project.get(to.params.pName, to.params.pVersion));
      customization.startCustomization();
      next();
    } catch (err) {
      console.error(err);
      next("/");
    }
  },
  async beforeRouteUpdate(to, from, next) {
    if (
      engineStore.basicData.name == to.params.pName &&
      engineStore.basicData.version == to.params.pVersion &&
      customization.customizing
    ) {
      next();
      return;
    }
    try {
      engineStore.load(await Project.get(to.params.pName, to.params.pVersion));
      customization.startCustomization();
      next();
    } catch (err) {
      console.error(err);
      next("/");
    }
  },
};
</script>
