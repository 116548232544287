var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  autocomplete: "username",
                  autofocus: "",
                  label: _vm.$t("login.username"),
                },
                model: {
                  value: _vm.user,
                  callback: function ($$v) {
                    _vm.user = $$v
                  },
                  expression: "user",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  autocomplete: "password",
                  label: _vm.$t("login.password"),
                  type: "password",
                },
                on: {
                  keypress: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.logIn.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-4", attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.logIn } },
            [_vm._v(_vm._s(_vm.$t("login.login")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }