var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.dialog, scrollable: "" },
      on: { "click:outside": _vm.close },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                {
                  staticClass: "white--text",
                  attrs: { align: "center", "no-gutters": "" },
                },
                [
                  _c("v-col", { attrs: { cols: "9" } }, [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm.img.title)),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "text-right", attrs: { cols: "3" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { text: "" },
                          on: { click: _vm.close },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { align: "center", dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "1", align: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: _vm.selectedIndex <= 0 },
                          on: {
                            click: function ($event) {
                              return _vm.prev()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "10", align: "center" } },
                    [
                      _c("custom-image", {
                        staticClass: "white lighten-2 image-container",
                        attrs: {
                          img: _vm.img,
                          "max-height": "600px",
                          contain: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "1", align: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.selectedIndex >= _vm.images.length - 1,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.next()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }