var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-row",
            {
              attrs: {
                align: "center",
                justify: "space-between",
                "no-gutters": "",
              },
            },
            [
              _c("v-col", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v(" " + _vm._s(_vm.name) + " "),
                ]),
              ]),
              _c(
                "v-col",
                { staticClass: "text-right" },
                [
                  _c("v-btn", [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]),
                  _vm.editable
                    ? _c("v-btn", { staticClass: "success ml-2" }, [
                        _vm._v(" " + _vm._s(_vm.$t("edit")) + " "),
                      ])
                    : _vm._e(),
                  _vm.removable
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "error ml-2",
                          on: {
                            click: function ($event) {
                              _vm.deleteDialog = true
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex",
                  staticStyle: { "flex-direction": "column" },
                  attrs: { cols: "12", md: _vm.textFieldsMdCols },
                },
                _vm._l(_vm.textFieldProperties, function (property) {
                  return _c(
                    "v-row",
                    {
                      key: property.name,
                      attrs: { align: "center", dense: "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right font-weight-bold",
                          attrs: { cols: "3", md: "2" },
                        },
                        [_vm._v(" " + _vm._s(property.name) + ": ")]
                      ),
                      _vm.isEntity(property)
                        ? _c(
                            "v-col",
                            { attrs: { cols: "9", md: "10" } },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: _vm.$route.path } },
                                [_vm._v(" A " + _vm._s(property.name) + " ")]
                              ),
                            ],
                            1
                          )
                        : property.class == "Date"
                        ? _c("v-col", { attrs: { cols: "9", md: "10" } }, [
                            _vm._v(" " + _vm._s(_vm.currentDate()) + " "),
                          ])
                        : property.class == "DateTime"
                        ? _c("v-col", { attrs: { cols: "9", md: "10" } }, [
                            _vm._v(" " + _vm._s(_vm.currentDateTime()) + " "),
                          ])
                        : _vm.isEnum(property)
                        ? _c("v-col", { attrs: { cols: "9", md: "10" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.randomEnumValue(property)) + " "
                            ),
                          ])
                        : _c("v-col", { attrs: { cols: "9", md: "10" } }, [
                            _vm._v(" A " + _vm._s(property.name) + " "),
                          ]),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._l(_vm.columnProperties, function (property) {
                return _c(
                  "v-col",
                  {
                    key: property.name,
                    staticClass: "d-flex",
                    staticStyle: { "flex-direction": "column" },
                    attrs: { cols: "12", md: "6" },
                  },
                  [
                    _c(
                      "v-row",
                      { attrs: { align: "center", dense: "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-right font-weight-bold",
                            attrs: { cols: "3", md: "2" },
                          },
                          [_vm._v(" " + _vm._s(property.name) + ": ")]
                        ),
                        _vm.isGeographic(property)
                          ? _c(
                              "v-col",
                              { attrs: { cols: "9", md: "10" } },
                              [
                                _c("map-field", {
                                  attrs: {
                                    id: property.name,
                                    height: "400px",
                                    editable: false,
                                    entity: { sampleGeom: null },
                                    propName: "sampleGeom",
                                  },
                                }),
                              ],
                              1
                            )
                          : property.class == "IGGallery"
                          ? _c(
                              "v-col",
                              { attrs: { cols: "9", md: "10" } },
                              [_c("v-card", [_c("gallery")], 1)],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._l(_vm.rowProperties, function (property) {
                return _c(
                  "v-col",
                  {
                    key: property.name,
                    staticClass: "d-flex",
                    staticStyle: { "flex-direction": "column" },
                    attrs: { cols: "12" },
                  },
                  [
                    _c(
                      "v-row",
                      { attrs: { align: "center", dense: "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-right font-weight-bold",
                            attrs: { cols: "3", md: "1" },
                          },
                          [_vm._v(" " + _vm._s(property.name) + ": ")]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "9", md: "11" } },
                          [
                            _c("gc-address-detail", {
                              attrs: { id: property.name },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("modal-dialog", {
        attrs: {
          dialog: _vm.deleteDialog,
          titleClass: "error white--text",
          titleIcon: "warning",
          submitClass: "error",
          submitText: _vm.$t("delete"),
          title: _vm.$t("delete_title"),
          content: _vm.$t("delete_content"),
        },
        on: {
          cancel: function ($event) {
            _vm.deleteDialog = false
          },
          submit: function ($event) {
            _vm.deleteDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }