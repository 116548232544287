import { dia, shapes } from "jointjs";

shapes.custom.Association = dia.Link.extend({
  defaults: {
    type: "custom.Association",
    attrs: {},
    smooth: true,
    bidirectional: false,
    sourceOpts: {
      label: null,
      multiplicity: "0..1",
    },
    targetOpts: {
      label: null,
      multiplicity: "0..1",
    },
    toolMarkup:
      '<g class="link-tools" >' +
      '<g class="tool-remove" event="tool:remove">' +
      '<circle r="11" />' +
      '<path transform="scale(.8) translate(-16, -16)" d="M24.778,21.419 19.276,15.917 24.777,10.415 21.949,7.585 16.447,13.087 10.945,7.585 8.117,10.415 13.618,15.917 8.116,21.419 10.946,24.248 16.447,18.746 21.948,24.248z"/>' +
      "<title>Remove link.</title>" +
      "</g>" +
      "</g>",
  },
  initialize: function () {
    // change does not work in this case, updateConnection must be called manually
    this.on(
      "change:bidirectional change:sourceOpts change:targetOpts",
      function () {
        this.updateConnection();
        this.trigger("uml-update");
      },
      this
    );

    this.updateConnection();
    dia.Link.prototype.initialize.apply(this, arguments);
  },

  updateConnection: function () {
    const attrs = this.get("attrs");

    const sourceOpts = this.get("sourceOpts");
    const targetOpts = this.get("targetOpts");

    attrs[".marker-target"] = {
      fill: "#4b4a67",
      stroke: "#4b4a67",
      d: "M 10 0 L 0 5 L 10 10",
      "fill-opacity": 1,
    };
    this.set("labels", [
      { position: 15, attrs: { text: { text: targetOpts.multiplicity } } },
      { position: 50, attrs: { text: { text: targetOpts.label } } },
      { position: -50, attrs: { text: { text: sourceOpts.label } } },
      { position: -15, attrs: { text: { text: sourceOpts.multiplicity } } },
    ]);
  },
});
