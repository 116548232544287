<template>
  <div>
    <nav-bar>
      <template v-slot:title>
        {{ pageTitle }}
      </template>
    </nav-bar>

    <router-view></router-view>
  </div>
</template>

<script>
import store from "@/services/store";
import NavBar from "@/components/common/NavBar";

export default {
  components: { NavBar },
  computed: {
    pageTitle() {
      return store.navigation.pageTitle;
    },
  },
};
</script>
