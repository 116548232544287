import "./uml-editor";

import "./scss/main";

import Vue from "vue";

import vmodal from "vue-js-modal";
Vue.use(vmodal, { dynamic: true, injectModalsContainer: true });

import Notifications from "vue-notification";
Vue.use(Notifications, { componentName: "Notifications" });

import vuetify from "./plugins/vuetify";

import i18n from "./plugins/i18n";

import "./plugins/leaflet";

import "./plugins/ckeditor";

import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
