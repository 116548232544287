var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { md: "3" } },
            [
              _c("p", { staticClass: "text-center blue--text" }, [
                _vm._v(_vm._s(_vm.$t("project.button.actions"))),
              ]),
              _c("v-divider"),
              _c(
                "v-btn",
                {
                  attrs: { block: "", text: "", color: "warning" },
                  on: {
                    click: function ($event) {
                      _vm.leaveParametrizationDialog = true
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("custom.button.leave")) + " ")]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { right: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: { block: "", text: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.exportJSON()
                                  },
                                },
                              },
                              on
                            ),
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("custom.button.export")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("custom.button.exportTooltip"))),
                  ]),
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: { block: "", text: "", color: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.generate()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("custom.button.generate")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-dialog", {
        attrs: {
          dialog: _vm.leaveParametrizationDialog,
          title: _vm.$t("warning"),
          titleClass: "warning white--text",
          titleIcon: "warning",
          submitClass: "warning",
          submitText: _vm.$t("continue"),
          content: _vm.$t("custom.leave_dialog"),
        },
        on: {
          cancel: function ($event) {
            _vm.leaveParametrizationDialog = false
          },
          submit: function ($event) {
            return _vm.leaveParametrization()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }