var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "mt-8", attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.validForm,
            callback: function ($$v) {
              _vm.validForm = $$v
            },
            expression: "validForm",
          },
        },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: [(v) => !!v || _vm.$t("project.validation.name")],
                      required: "",
                      label: _vm.$t("project.project_name"),
                    },
                    model: {
                      value: _vm.specificationModified.basicData.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.specificationModified.basicData,
                          "name",
                          $$v
                        )
                      },
                      expression: "specificationModified.basicData.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      rules: [
                        (v) => !!v || _vm.$t("project.validation.version"),
                        (v) =>
                          /^(?!0\.0\.0$)(\d+\.){2}\d+$/.test(v) ||
                          _vm.$t("project.validation.version_format"),
                      ],
                      required: "",
                      label: _vm.$t("project.project_version"),
                      hint: _vm.$t("project.validation.version_format"),
                    },
                    model: {
                      value: _vm.specificationModified.basicData.version,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.specificationModified.basicData,
                          "version",
                          $$v
                        )
                      },
                      expression: "specificationModified.basicData.version",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "mt-4",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "v-col",
                { attrs: { md: "3" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { block: "", text: "", disabled: !_vm.validForm },
                      on: {
                        click: function ($event) {
                          return _vm.createProject()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("project.button.create")) + " "
                      ),
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { block: "", text: "", color: "error" },
                      on: {
                        click: function ($event) {
                          return _vm.cancelEditMode()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }