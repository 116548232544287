import axios from "./axios";
import store from "./store";
import i18n from "@/plugins/i18n";

const authStore = store.auth;

const _public = {
  userIsLogged,
  login,
  logout,
  getToken,
  isAuthenticationChecked: _checkAuthentication(),
};

function userIsLogged() {
  return authStore.logged;
}

function login(credentials) {
  return axios.post("login", credentials).then((response) => {
    _saveToken(response.data.token);
    return _authenticate();
  });
}

function logout() {
  _removeToken();
  authStore.user = "";
  authStore.logged = false;
}

function getToken() {
  return localStorage.getItem("token");
}

// usamos localStorage para guardar el token, de forma
// que sea persistente (se inhabilita con el tiempo o
// al hacer logout)
function _saveToken(token) {
  localStorage.setItem("token", token);
  localStorage.setItem("lang", i18n.locale);
}

function _removeToken() {
  localStorage.removeItem("token");
  localStorage.removeItem("lang");
}

// si tenemos el token guardado, esta petición se hará
// con el filtro definido en http-common y por tanto nos
// devolverá el usuario logueado
function _authenticate() {
  return axios.get("login/logged").then((response) => {
    authStore.user = response.data.username;
    authStore.logged = true;
    return authStore;
  });
}

function _checkAuthentication() {
  return new Promise((res) => {
    if (getToken()) {
      _authenticate()
        .then(() => {
          const locale = localStorage.getItem("lang");
          if (locale) {
            i18n.locale = locale;
          }
        })
        .catch(() => logout())
        .finally(() => res(true));
    } else {
      res(true);
    }
  });
}

export default _public;
