<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col md="3">
        <p class="text-center blue--text">{{ $t("project.button.actions") }}</p>
        <v-divider></v-divider>
        <v-btn
          block
          text
          color="warning"
          @click="leaveParametrizationDialog = true"
        >
          {{ $t("custom.button.leave") }}
        </v-btn>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn block text v-on="on" @click="exportJSON()">
              {{ $t("custom.button.export") }}
            </v-btn>
          </template>
          <span>{{ $t("custom.button.exportTooltip") }}</span>
        </v-tooltip>

        <v-btn block text color="success" @click="generate()">
          {{ $t("custom.button.generate") }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- Dialog to change phase from personalization to specification -->
    <modal-dialog
      @cancel="leaveParametrizationDialog = false"
      @submit="leaveParametrization()"
      :dialog="leaveParametrizationDialog"
      :title="$t('warning')"
      titleClass="warning white--text"
      titleIcon="warning"
      submitClass="warning"
      :submitText="$t('continue')"
      :content="$t('custom.leave_dialog')"
    ></modal-dialog>
  </v-container>
</template>

<script>
import customization from "@/services/customization.service";
import engineStore from "@/services/engineStore";
import normalize from "@/services/normalize";
import FileSaver from "file-saver";
import ModalDialog from "@/components/common/ModalDialog";

export default {
  components: { ModalDialog },
  data() {
    return {
      leaveParametrizationDialog: false,
    };
  },
  methods: {
    exportJSON() {
      const blob = new Blob(
        [JSON.stringify(customization.cleanJson(), null, 2)],
        {
          type: "text/plain;charset=utf-8",
        }
      );
      FileSaver.saveAs(
        blob,
        normalize(customization.data.data.basicData.name, true) +
          "." +
          customization.data.data.basicData.version +
          ".cli.json"
      );
    },
    generate() {
      engineStore.engine.generateZip(customization.cleanJson()).then((zip) => {
        zip.generateAsync({ type: "blob" }).then((file) => {
          FileSaver.saveAs(
            file,
            normalize(customization.data.data.basicData.name, true) +
              "." +
              customization.data.data.basicData.version +
              ".zip"
          );
        });
      });
    },
    leaveParametrization() {
      this.$router.push({
        name: "ProjectMain",
        params: {
          pName: engineStore.basicData.name,
          pVersion: engineStore.basicData.version,
        },
      });
    },
  },
};
</script>
