var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { persistent: "", width: "500", value: _vm.dialog } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "headline",
              class: _vm.titleClass,
              attrs: { "primary-title": "" },
            },
            [
              _vm.titleIcon
                ? _c("v-icon", { staticClass: "mr-4", class: _vm.titleClass }, [
                    _vm._v(" " + _vm._s(_vm.titleIcon) + " "),
                  ])
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.title) + " "),
            ],
            1
          ),
          _c("v-card-text", {
            staticClass: "mt-4",
            domProps: { innerHTML: _vm._s(_vm.content) },
          }),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  class: _vm.cancelClass,
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.cancelText) + " ")]
              ),
              _vm.submitText
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-4",
                      class: _vm.submitClass,
                      on: {
                        click: function ($event) {
                          return _vm.$emit("submit")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.submitText) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }