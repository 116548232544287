import axios from "./axios";

export default {
  async getAll() {
    return (await axios.get("projects")).data;
  },

  async getVersions(projectName) {
    return (await axios.get(`projects/${projectName}/versions`)).data;
  },

  async get(projectName, version) {
    return (await axios.get(`projects/${projectName}/versions/${version}`))
      .data;
  },

  async create(spec) {
    return (await axios.post(`projects`, spec)).data;
  },

  async update(spec, basicData) {
    if (!basicData) {
      basicData = spec.basicData;
    }
    return (
      await axios.put(
        `projects/${basicData.name}/versions/${basicData.version}`,
        spec
      )
    ).data;
  },

  async remove(projectName, version) {
    return await axios.delete(`projects/${projectName}/versions/${version}`);
  },
};
