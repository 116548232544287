<template>
  <modal-dialog
    @cancel="$emit('close')"
    @submit="remove"
    titleClass="error white--text"
    titleIcon="warning"
    submitClass="error"
    :submitText="$t('delete')"
    :submit-text="$t('delete')"
    :dialog="dialog"
    :title="$t('uml.dialog.relationship.delete_title')"
    :content="
      $t('uml.dialog.relationship.delete_dialog', {
        source: source,
        srcLabel: srcLabel,
        target: target,
        tgtLabel: tgtLabel,
      })
    "
  ></modal-dialog>
</template>
<script>
import graphHelper from "../graph.helper";
import ModalDialog from "@/components/common/ModalDialog";
export default {
  name: "ModalRelationshipDeleteDialog",
  components: { ModalDialog },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    model: {
      validator: (prop) => typeof prop === "object" || prop === null,
      required: true,
    },
  },
  data() {
    return {
      source: "",
      srcLabel: "",
      target: "",
      tgtLabel: "",
    };
  },
  computed: {
    entities() {
      if (!this.dialog) return [];
      return graphHelper.getEntities();
    },
  },
  watch: {
    model: {
      handler() {
        this._updateData();
      },
      immediate: true,
    },
  },
  methods: {
    _updateData() {
      this.source = this.entities.find(
        (e) => e.id === this.model.attributes.source.id
      ).attributes.name;
      this.target = this.entities.find(
        (e) => e.id === this.model.attributes.target.id
      ).attributes.name;
      // NOTE: source and target labels have to be set inversely
      this.srcLabel = this.model.attributes.targetOpts.label;
      this.tgtLabel = this.model.attributes.sourceOpts.label;
    },
    remove() {
      this.model.remove();
      this.$emit("close");
    },
  },
};
</script>
