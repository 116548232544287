var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "transition",
        { attrs: { name: "slide-fade" } },
        [
          _vm.showDBConfig
            ? _c("database-configuration", {
                attrs: { config: _vm.store.basicData.database },
                on: {
                  input: (newVal) => (_vm.store.basicData.database = newVal),
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                md: "7",
                lg: "6",
                "offset-xl": "0",
                xl: "4",
              },
            },
            [
              _c("p", { staticClass: "text-center blue--text" }, [
                _vm._v(_vm._s(_vm.$t("project.button.actions"))),
              ]),
              _c("v-divider"),
              _c(
                "v-btn",
                {
                  attrs: { "mt-2": "", text: "", block: "" },
                  on: {
                    click: function ($event) {
                      return _vm.newProjectFromCurrent()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("project.button.duplicate")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { block: "", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.renameProject()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("project.button.rename")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { block: "", text: "", color: "error" },
                  on: {
                    click: function ($event) {
                      _vm.deleteDialog = true
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("project.button.deleteProject")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                md: "7",
                lg: "6",
                "offset-xl": "0",
                xl: "4",
              },
            },
            [
              _c("p", { staticClass: "text-center blue--text" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("project.button.configuration")) + " "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showDBConfig,
                      expression: "!showDBConfig",
                    },
                  ],
                  attrs: { block: "", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.showDBConfig = !_vm.showDBConfig
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("project.button.show_database")) + " "
                  ),
                ]
              ),
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showDBConfig,
                      expression: "showDBConfig",
                    },
                  ],
                  attrs: { block: "", color: "warning", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.showDBConfig = !_vm.showDBConfig
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("project.button.hide_database")) + " "
                  ),
                ]
              ),
              _vm.listsOrFormsSelected
                ? _c(
                    "v-btn",
                    {
                      attrs: { block: "", color: "warning", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.enterParametrizationDialog = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("project.button.parametrize")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                md: "7",
                lg: "6",
                "offset-xl": "0",
                xl: "4",
              },
            },
            [
              _c("p", { staticClass: "text-center blue--text" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("project.button.import_export")) + " "
                ),
              ]),
              _c("v-divider"),
              _c(
                "v-btn",
                {
                  attrs: { "pt-4": "", block: "", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.startImportJSON()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("project.button.import")) + " ")]
              ),
              _c("v-divider"),
              _c(
                "v-btn",
                {
                  attrs: { block: "", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.exportJSON()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("project.button.export")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { block: "", text: "", color: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.generate()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("project.button.generate")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("input", {
        ref: "hiddenUploader",
        staticClass: "d-none",
        attrs: { type: "file" },
      }),
      _c("modal-dialog", {
        attrs: {
          dialog: _vm.deleteDialog,
          title: _vm.$t("project.delete_title"),
          titleClass: "error white--text",
          titleIcon: "warning",
          submitClass: "error",
          submitText: _vm.$t("delete"),
          content: _vm.$t("project.delete_dialog"),
        },
        on: {
          cancel: function ($event) {
            _vm.deleteDialog = false
          },
          submit: function ($event) {
            return _vm.removeVersion()
          },
        },
      }),
      _c("modal-dialog", {
        attrs: {
          dialog: _vm.enterParametrizationDialog,
          title: _vm.$t("warning"),
          titleClass: "warning white--text",
          titleIcon: "warning",
          submitClass: "warning",
          submitText: _vm.$t("project.button.parametrize"),
          content: _vm.$t("project.parametrization_dialog"),
        },
        on: {
          cancel: function ($event) {
            _vm.enterParametrizationDialog = false
          },
          submit: function ($event) {
            return _vm.loadParametrization()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }