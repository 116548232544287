var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.address
        ? _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("label", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.$t("gc_address.line1"))),
                          ]),
                          _c("div", [_vm._v(_vm._s(_vm.address.line1 || "-"))]),
                          _c("label", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.$t("gc_address.line2"))),
                          ]),
                          _c("div", [_vm._v(_vm._s(_vm.address.line2 || "-"))]),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "label",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(_vm._s(_vm.$t("gc_address.zipcode")))]
                                ),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.address.zipCode || "-")),
                                ]),
                              ]),
                              _c("v-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "label",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(_vm._s(_vm.$t("gc_address.country")))]
                                ),
                                _c("div", [_vm._v(_vm._s(_vm.countryName))]),
                              ]),
                              _c("v-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "label",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("gc_address.subdivision1"))
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.subdivision1Name)),
                                ]),
                              ]),
                              _c("v-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "label",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("gc_address.subdivision2"))
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.subdivision2Name)),
                                ]),
                              ]),
                              _c("v-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "label",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("gc_address.subdivision3"))
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.subdivision3Name)),
                                ]),
                              ]),
                              _c("v-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "label",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("gc_address.subdivision4"))
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.subdivision4Name)),
                                ]),
                              ]),
                              _c("v-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "label",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(_vm._s(_vm.$t("gc_address.town")))]
                                ),
                                _c("div", [_vm._v(_vm._s(_vm.townName))]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "label",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("gc_address.latitude"))
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.address.point.coordinates[1])
                                  ),
                                ]),
                              ]),
                              _c("v-col", { attrs: { cols: "6" } }, [
                                _c(
                                  "label",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("gc_address.longitude"))
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.address.point.coordinates[0])
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("gc-map", {
                            attrs: { id: _vm.id, feature: _vm.address.point },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }