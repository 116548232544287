var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                {
                  attrs: {
                    align: "center",
                    justify: "space-between",
                    "no-gutters": "",
                  },
                },
                [
                  _c("v-col", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm.$t("custom.list.title.form"))),
                    ]),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2",
                          attrs: { to: { name: "ListCustomization_List" } },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary ml-2",
                          attrs: { disabled: !_vm.validForm },
                          on: { click: () => (_vm.dialog = !_vm.dialog) },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("preview")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "success ml-2",
                          attrs: { disabled: !_vm.validForm },
                          on: { click: _vm.save },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.validForm,
                    callback: function ($$v) {
                      _vm.validForm = $$v
                    },
                    expression: "validForm",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "6" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      label: _vm.$t("custom.list.listId"),
                                      rules: [
                                        (v) =>
                                          !!v || _vm.$t("validation.required"),
                                      ],
                                    },
                                    model: {
                                      value: _vm.listElement.id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listElement, "id", $$v)
                                      },
                                      expression: "listElement.id",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      items: _vm.entitiesDisp,
                                      "item-text": "name",
                                      label: _vm.$tc("entity.name", 1),
                                      rules: [
                                        (v) =>
                                          !!v || _vm.$t("validation.required"),
                                      ],
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.reloadProperties(false)
                                      },
                                    },
                                    model: {
                                      value: _vm.listElement.entity,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listElement, "entity", $$v)
                                      },
                                      expression: "listElement.entity",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      disabled: !_vm.formLinkSelected,
                                      clearable: "",
                                      items: _vm.formsDisp,
                                      "item-text": "id",
                                      "return-object": "",
                                      label: _vm.$t("custom.list.form"),
                                    },
                                    on: {
                                      "click:clear": function ($event) {
                                        return _vm.clear()
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        !_vm.formLinkSelected
                                          ? {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-help-circle"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        2492510221
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "custom.list.mandatory_feature",
                                                              {
                                                                param: _vm.$t(
                                                                  "GUI_L_FormLink.label"
                                                                ),
                                                              }
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.listElement.form,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listElement, "form", $$v)
                                      },
                                      expression: "listElement.form",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      id: "filter",
                                      dense: "",
                                      disabled: !_vm.filterableSelected,
                                      label: _vm.$t("custom.list.staticFilter"),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        !_vm.filterableSelected
                                          ? {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-help-circle"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        2492510221
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "custom.list.mandatory_feature",
                                                              {
                                                                param: _vm.$t(
                                                                  "GUI_L_Filterable.label"
                                                                ),
                                                              }
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "3" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "checkbox-dense",
                                    attrs: {
                                      dense: "",
                                      disabled: !_vm.removableSelected,
                                      label: _vm.$t("custom.list.linkRemove"),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        !_vm.removableSelected
                                          ? {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-help-circle"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        2492510221
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "custom.list.mandatory_feature",
                                                              {
                                                                param: _vm.$t(
                                                                  "GUI_F_Removable.label"
                                                                ),
                                                              }
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.listElement.removeLink,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listElement,
                                          "removeLink",
                                          $$v
                                        )
                                      },
                                      expression: "listElement.removeLink",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "3" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "checkbox-dense",
                                    attrs: {
                                      dense: "",
                                      disabled: !_vm.sortableSelected,
                                      label: _vm.$t("custom.list.sorting"),
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        !_vm.sortableSelected
                                          ? {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-help-circle"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        2492510221
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "custom.list.mandatory_feature",
                                                              {
                                                                param: _vm.$t(
                                                                  "GUI_L_Sortable.label"
                                                                ),
                                                              }
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.listElement.sorting,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listElement,
                                          "sorting",
                                          $$v
                                        )
                                      },
                                      expression: "listElement.sorting",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "3" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "checkbox-dense",
                                    attrs: {
                                      dense: "",
                                      disabled: !_vm.basicSearchSelected,
                                      label: _vm.$t("custom.list.searching"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateSearching()
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        !_vm.basicSearchSelected
                                          ? {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-help-circle"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        2492510221
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "custom.list.mandatory_feature",
                                                              {
                                                                param: _vm.$t(
                                                                  "GUI_L_F_BasicSearch.label"
                                                                ),
                                                              }
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.listElement.searching,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listElement,
                                          "searching",
                                          $$v
                                        )
                                      },
                                      expression: "listElement.searching",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "3" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "checkbox-dense",
                                    attrs: {
                                      dense: "",
                                      disabled: !_vm.rowFilterSelected,
                                      label: _vm.$t("custom.list.filtering"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateFiltering()
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        !_vm.rowFilterSelected
                                          ? {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-help-circle"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        2492510221
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "custom.list.mandatory_feature",
                                                              {
                                                                param: _vm.$t(
                                                                  "GUI_L_F_RowFilter.label"
                                                                ),
                                                              }
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            }
                                          : null,
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.listElement.filtering,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listElement,
                                          "filtering",
                                          $$v
                                        )
                                      },
                                      expression: "listElement.filtering",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      dense: "",
                                      label: _vm.$t("custom.list.instructions"),
                                    },
                                    model: {
                                      value: _vm.listElement.instructions,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listElement,
                                          "instructions",
                                          $$v
                                        )
                                      },
                                      expression: "listElement.instructions",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", { attrs: { vertical: "" } }),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "6" } },
                        [
                          _c("div", { staticClass: "title text-center mb-2" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$tc("property.name", 2)) + " "
                            ),
                          ]),
                          _c("v-data-table", {
                            attrs: {
                              dense: "",
                              headers: _vm.headers,
                              items: _vm.propertiesDisp,
                              "hide-default-footer": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `item.selected`,
                                  fn: function ({ item }) {
                                    return [
                                      _c("v-checkbox", {
                                        staticClass: "selected-check",
                                        on: {
                                          change: function ($event) {
                                            return _vm.selectProperty(
                                              $event,
                                              item
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.selected,
                                          callback: function ($$v) {
                                            _vm.$set(item, "selected", $$v)
                                          },
                                          expression: "item.selected",
                                        },
                                      }),
                                    ]
                                  },
                                },
                                {
                                  key: `item.form`,
                                  fn: function ({ item }) {
                                    return [
                                      item.posibleForms && _vm.formLinkSelected
                                        ? _c("v-select", {
                                            staticClass: "selected-form",
                                            attrs: {
                                              "item-text": "id",
                                              items: item.posibleForms,
                                              disabled: !item.selected,
                                            },
                                            model: {
                                              value: item.form,
                                              callback: function ($$v) {
                                                _vm.$set(item, "form", $$v)
                                              },
                                              expression: "item.form",
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dialog
        ? _c("list-preview", {
            attrs: {
              dialog: _vm.dialog,
              searching: _vm.listElement.searching,
              exportable: _vm.exportSelected,
              properties: _vm.selectedProperties,
              removeLink: _vm.listElement.removeLink,
              form: _vm.listElement.form,
              title: _vm.listElement.id,
            },
            on: {
              exit: function ($event) {
                _vm.dialog = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }