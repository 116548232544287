<template>
  <v-card>
    <v-card-text>
      <v-row align="center" justify="space-between">
        <v-col>
          <span class="headline">{{ name }}</span>
        </v-col>

        <v-col class="text-right">
          <v-btn>{{ $t("cancel") }}</v-btn>

          <v-btn class="success ml-2">{{ $t("save") }}</v-btn>
        </v-col>
      </v-row>
      <v-form>
        <v-row dense>
          <v-col cols="12" :md="textFieldsMdCols">
            <v-row dense>
              <v-col
                class="field-container"
                cols="12"
                :md="singleTextFieldMdCols"
                v-for="property in textFieldProperties"
                :key="property.name"
              >
                <v-text-field
                  dense
                  type="number"
                  :disabled="true"
                  :label="property.name"
                  v-if="property.class.indexOf('autoinc') != -1"
                ></v-text-field>
                <number-field
                  dense
                  :type="property.class"
                  :label="property.name"
                  v-else-if="isNumber(property)"
                ></number-field>
                <v-switch
                  dense
                  class="ma-2 tp-0"
                  :label="property.name"
                  v-else-if="property.class == 'Boolean'"
                ></v-switch>
                <date-and-hour-picker
                  v-else-if="property.class == 'Date'"
                  :datePickerProp="{
                    data: null,
                    label: property.name,
                  }"
                ></date-and-hour-picker>
                <date-and-hour-picker
                  v-else-if="property.class == 'DateTime'"
                  :datePickerProp="{
                    data: null,
                    label: property.name,
                  }"
                  :timePickerProp="{
                    data: null,
                    label: property.name,
                  }"
                ></date-and-hour-picker>
                <v-text-field
                  v-else-if="property.class == 'String'"
                  dense
                  type="text"
                  :label="property.name"
                ></v-text-field>
                <v-select
                  dense
                  :label="property.name"
                  :items="enumValues(property)"
                  v-if="isEnum(property)"
                ></v-select>
                <v-select
                  v-else-if="isEntity(property)"
                  dense
                  :label="property.name"
                  :multiple="property.multiple"
                  return-object
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-for="property in columnProperties"
            :key="property.name"
            cols="12"
            md="6"
            class="field-container"
          >
            <v-col cols="12" class="font-weight-bold">
              {{ property.name }}:
            </v-col>
            <v-col cols="12">
              <map-field
                :id="property.name"
                height="400px"
                :entity="{ sampleGeom: null }"
                :geomType="property.class"
                :editable="true"
                propName="sampleGeom"
                v-if="isGeographic(property)"
              ></map-field>
              <v-card v-else-if="property.class == 'IGGallery'">
                <gallery :isEdit="true"></gallery>
              </v-card>
            </v-col>
          </v-col>
          <v-col
            v-for="property in rowProperties"
            :key="property.name"
            cols="12"
            class="field-container"
          >
            <v-col cols="12" class="font-weight-bold">
              {{ property.name }}:
            </v-col>
            <gc-address-form
              :id="property.name"
              v-if="property.class == 'GCAddress'"
            ></gc-address-form>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import customization from "@/services/customization.service";
import MapField from "@/components/common/map-field/MapField.vue";
import GCAddressForm from "@/components/common/gc-address/GCAddressForm";
import Gallery from "@/components/common/gallery/Gallery";
import DateAndHourPicker from "@/components/common/calendar/DateAndHourPicker.vue";
import NumberField from "@/components/common/number-field/NumberField.vue";
const columnTypes = [
  "IGGallery",
  "Geometry",
  "Polygon",
  "LineString",
  "Point",
  "MultiPolygon",
  "MultiLineString",
  "MultiPoint",
];
const rowTypes = ["GCAddress"];
export default {
  name: "FormPreview",
  props: ["properties", "editability", "name"],
  components: {
    MapField,
    "gc-address-form": GCAddressForm,
    Gallery,
    DateAndHourPicker,
    NumberField,
  },
  data() {
    return {
      customization,
      entities: [],
      enums: [],
    };
  },
  created() {
    this.entities = this.customization.data.data.dataModel.entities;
    this.enums = this.customization.data.data.dataModel.enums;
  },
  computed: {
    textFieldProperties() {
      // Everything that isn't a column or a row property
      return this.properties.filter(
        (prop) =>
          this.isEditable(prop) &&
          !columnTypes.includes(prop.class) &&
          !rowTypes.includes(prop.class)
      );
    },
    columnProperties() {
      return this.properties.filter(
        (prop) => this.isEditable(prop) && columnTypes.includes(prop.class)
      );
    },
    rowProperties() {
      return this.properties.filter(
        (prop) => this.isEditable(prop) && rowTypes.includes(prop.class)
      );
    },
    textFieldsMdCols() {
      return this.columnProperties.length >= 1 ? 6 : 12;
    },
    singleTextFieldMdCols() {
      return this.columnProperties.length >= 1 ? 12 : 6;
    },
  },
  methods: {
    isNumber(property) {
      return (
        property.class == "Long" ||
        property.class == "Integer" ||
        property.class == "BigDecimal" ||
        property.class == "Float" ||
        property.class == "Double"
      );
    },
    isEnum(property) {
      return this.enums.find((el) => el.name == property.class);
    },
    isEntity(property) {
      return (
        this.entities.find((el) => el.name == property.class) && property.owner
      );
    },
    isGeographic(property) {
      return (
        property.class == "Point" ||
        property.class == "LineString" ||
        property.class == "Polygon" ||
        property.class == "MultiPoint" ||
        property.class == "MultiLineString" ||
        property.class == "MultiPolygon"
      );
    },
    mdCols(property) {
      if (this.isGeographic(property)) return 12;
      else return 6;
    },
    isEditable(property) {
      return this.editability.find((el) => el.property == property.name)
        .editing;
    },
    enumValues(property) {
      const index = this.enums.findIndex((el) => el.name == property.class);
      let enumerated = this.enums[index];
      return enumerated.values;
    },
  },
};
</script>
<style scoped>
.field-container {
  margin-bottom: auto;
}
</style>
