<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="2">
        <v-select
          v-model="selected.name"
          class="selector"
          :items="projects"
          :placeholder="$t('project.placeholder.name')"
          @change="getVersions"
          :loading="projectsLoading"
        ></v-select>
      </v-col>
      <v-col cols="12" lg="2">
        <v-select
          v-model="selected.version"
          class="selector"
          :disabled="versions.length === 0"
          :items="versions"
          :placeholder="$t('project.placeholder.version')"
          :loading="versionsLoading"
        ></v-select>
      </v-col>
      <v-col cols="12" lg="1">
        <v-btn
          :disabled="!selected.version"
          outlined
          color="primary"
          @click="loadProject"
        >
          {{ $t("load") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col md="3">
        <v-btn block text @click="newBlankProject()">
          {{ $t("project.button.empty") }}
        </v-btn>
        <v-btn block text @click="newProjectFromImport()">
          {{ $t("project.button.fromSpec") }}
        </v-btn>
      </v-col>
    </v-row>
    <input ref="hiddenUploader" type="file" class="d-none" />
  </v-container>
</template>

<script>
import engineStore from "@/services/engineStore";
import Project from "@/services/project.service";
import store from "@/services/store";
const reader = new FileReader();

export default {
  data() {
    return {
      selected: {
        name: "",
        version: "",
      },
      projects: [],
      projectsLoading: true,
      versions: [],
      versionsLoading: false,
    };
  },
  created() {
    store.navigation.pageTitle = this.$t("project.title.select");
    engineStore.resetSpec();
    this._getProjects();
  },
  mounted() {
    this._setupUploaderCallback();
  },
  methods: {
    async _getProjects() {
      this.projects = await Project.getAll();
      this.projects.sort((a, b) => a.localeCompare(b));
      this.projectsLoading = false;
    },
    loadProject() {
      this.$router.push({
        name: "ProjectMain",
        params: {
          pName: this.selected.name,
          pVersion: this.selected.version,
        },
      });
    },
    async getVersions() {
      this.versionsLoading = true;
      this.versions = await Project.getVersions(this.selected.name);
      this.versions.sort((a, b) => a.localeCompare(b)).reverse();
      this.selected.version = this.versions[0];
      this.versionsLoading = false;
    },
    newBlankProject() {
      this.$router.push("/new");
    },
    newProjectFromImport() {
      this.$refs.hiddenUploader.click();
    },
    _setupUploaderCallback() {
      this.$refs.hiddenUploader.addEventListener("change", (evt) => {
        reader.onload = (evt) => {
          const result = JSON.parse(evt.target.result);
          if (result.data) {
            this.$notify({
              text: this.$t("project.notification.error-importing-cli-spec"),
              type: "error",
            });
            this.$refs.hiddenUploader.value = null;
            return;
          }
          this.$notify({
            text: this.$t("project.notification.specification_read"),
            type: "success",
          });
          this.$router.push({
            name: "NewProject",
            params: {
              specification: result,
            },
          });
        };
        reader.readAsText(evt.target.files[0]);
      });
    },
  },
};
</script>
