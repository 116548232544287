import BaseWithProjectCustomization from "./BaseWithProjectCustomization";
import Customization from "./CustomizationExporter";
import listCustomizationRouter from "./list_customization/router.js";
import formCustomizationRouter from "./form_customization/router.js";

const routes = [
  {
    path: "/:pName/:pVersion/parametrization",
    component: BaseWithProjectCustomization,
    children: [].concat(
      [
        {
          name: "CustomizationExporter",
          path: "",
          component: Customization,
        },
      ],
      listCustomizationRouter,
      formCustomizationRouter
    ),
  },
];

export default routes;
