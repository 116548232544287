var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "image-container" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "6" } }, [
            _vm.images.length < 1
              ? _c("span", [
                  _vm._v(" " + _vm._s(_vm.$t("gallery.empty")) + " "),
                ])
              : _vm._e(),
          ]),
          _vm.isEdit
            ? _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "" },
                      on: { click: _vm.openCreateDialog },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("gallery.add")) + " ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.images.length >= 1
        ? _c(
            "v-row",
            {
              staticClass: "gallery-main d-flex child-flex",
              on: {
                mouseover: function ($event) {
                  _vm.isShowingOverlayCard = true
                },
                mouseleave: function ($event) {
                  _vm.isShowingOverlayCard = false
                },
              },
            },
            [
              _c("v-col", { attrs: { cols: "3" } }, [
                _vm.images[_vm.imageSelectedIndex].title ||
                _vm.images[_vm.imageSelectedIndex].description
                  ? _c("div", { staticClass: "gallery-header-info" }, [
                      _vm.images[_vm.imageSelectedIndex].title
                        ? _c("div", { staticClass: "title" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.images[_vm.imageSelectedIndex].title
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.images[_vm.imageSelectedIndex].description
                        ? _c("div", { staticClass: "description" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.images[_vm.imageSelectedIndex].description
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "v-col",
                { staticClass: "pt-0 pr-0 pl-0 pb-0", attrs: { cols: "6" } },
                [
                  _c("custom-image", {
                    staticClass: "black lighten-2 gallery-main-img",
                    attrs: {
                      img: _vm.imageSelected,
                      contain: "",
                      "max-height": "300px",
                    },
                    on: { click: _vm.openDialog },
                  }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "3" } }, [
                _vm.images[_vm.imageSelectedIndex].author ||
                _vm.images[_vm.imageSelectedIndex].year
                  ? _c("div", { staticClass: "gallery-footer-info" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.getImageFooter(
                              _vm.images[_vm.imageSelectedIndex]
                            )
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm.isEdit
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.isShowingOverlayCard &&
                            _vm.imageSelected &&
                            !_vm.imageSelected.deleted,
                          expression:
                            "isShowingOverlayCard && imageSelected && !imageSelected.deleted",
                        },
                      ],
                      staticClass: "overlay-card",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "buttons" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "warning overlay-card-button",
                              on: { click: _vm.openEditDialog },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("gallery.update")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "error overlay-card-button",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteItem()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("gallery.delete")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isEdit
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.isShowingOverlayCard &&
                            _vm.imageSelected &&
                            _vm.imageSelected.deleted,
                          expression:
                            "isShowingOverlayCard && imageSelected && imageSelected.deleted",
                        },
                      ],
                      staticClass: "overlay-card",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "buttons" },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "mb-2 white--text",
                              attrs: { color: "red", small: "" },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("mdi-delete"),
                              ]),
                              _c("span", { staticClass: "white--text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("gallery.image-to-delete")) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "warning overlay-card-button",
                              on: { click: _vm.undoDelete },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("gallery.undo")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.images.length >= 1
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "gallery-arrow text-center",
                  attrs: { cols: "2", md: "1" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.imageSelectedIndex > 0,
                          expression: "imageSelectedIndex > 0",
                        },
                      ],
                      attrs: { icon: "" },
                      on: { click: _vm.prev },
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "gallery-mini-container text-center",
                  attrs: { cols: "8", md: "10" },
                },
                _vm._l(_vm.images, function (img) {
                  return _c(
                    "v-col",
                    {
                      key: img.id,
                      staticClass: "gallery-mini",
                      attrs: { cols: "6", sm: "4", md: "3", lg: "2" },
                    },
                    [
                      _c("custom-image", {
                        staticClass: "white lighten-2 gallery-mini-img",
                        attrs: {
                          img: img,
                          "aspect-ratio": "1.7778",
                          height: "70px",
                          contain: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.selectImage(img)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "gallery-arrow text-center",
                  attrs: { cols: "2", md: "1" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.imageSelectedIndex < _vm.images.length - 1,
                          expression: "imageSelectedIndex < images.length - 1",
                        },
                      ],
                      attrs: { icon: "" },
                      on: { click: _vm.next },
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isEdit && _vm.dialog
        ? _c("image-form", {
            attrs: {
              dialog: _vm.dialog,
              image: _vm.imageSelected,
              create: _vm.creation,
            },
            on: {
              submit: _vm.manageEdition,
              cancel: function ($event) {
                _vm.dialog = false
              },
            },
          })
        : _vm._e(),
      !_vm.isEdit && _vm.dialog
        ? _c("image-detail", {
            attrs: {
              dialog: _vm.dialog,
              images: _vm.images,
              index: _vm.imageSelectedIndex,
            },
            on: { exit: _vm.closeDetailDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }