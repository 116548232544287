var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("nav-bar", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.pageTitle) + " ")]
            },
            proxy: true,
          },
        ]),
      }),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }