import { render, staticRenderFns } from "./GCMap.vue?vue&type=template&id=2b45a93a&scoped=true"
import script from "./GCMap.vue?vue&type=script&lang=js"
export * from "./GCMap.vue?vue&type=script&lang=js"
import style0 from "./GCMap.vue?vue&type=style&index=0&id=2b45a93a&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b45a93a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b45a93a')) {
      api.createRecord('2b45a93a', component.options)
    } else {
      api.reload('2b45a93a', component.options)
    }
    module.hot.accept("./GCMap.vue?vue&type=template&id=2b45a93a&scoped=true", function () {
      api.rerender('2b45a93a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/client/components/common/gc-address/GCMap.vue"
export default component.exports