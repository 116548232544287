var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("notifications", {
        attrs: {
          max: 3,
          width: 400,
          position: "top center",
          classes: "my-style mt-3",
        },
      }),
      _c("v-main", [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }