<template>
  <v-container>
    <v-card v-if="data">
      <v-card-title>
        <v-row align="start" justify="space-around" no-gutters>
          <v-col cols="10">
            <span>{{ $t("custom.list.title.list") }}</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn
              class="pa-3"
              color="success"
              :to="{ name: 'ListCustomization_Create' }"
            >
              {{ $t("new") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="data" hide-default-footer>
          <template v-slot:[`item.id`]="{ item }">
            <router-link
              :to="{ name: 'ListCustomization_Form', params: { id: item.id } }"
            >
              {{ item.id }}
            </router-link>
          </template>

          <!-- Actions -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="orange" @click="editList(item)">edit</v-icon>
            <v-icon color="red" @click="selectItemToDelete(item)"
              >delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>

      <!-- Dialog delete confirmation -->
      <modal-dialog
        @cancel="deleteDialog = false"
        @submit="deleteList()"
        :dialog="deleteDialog"
        :title="$t('custom.list.delete_title')"
        titleClass="error white--text"
        titleIcon="warning"
        submitClass="error"
        :submitText="$t('delete')"
        :content="$t('custom.list.delete_dialog')"
      ></modal-dialog>
    </v-card>
  </v-container>
</template>

<script>
import customization from "@/services/customization.service";
import ModalDialog from "@/components/common/ModalDialog";

export default {
  name: "ListCustomization",
  components: { ModalDialog },
  data() {
    return {
      customization,
      headers: [
        { text: this.$t("custom.list.listId"), value: "id" },
        { text: this.$tc("entity.name", 1), value: "entity" },
        { text: "", value: "actions", sortable: false },
      ],
      data: null,
      deleteDialog: false,
      itemSelected: null,
    };
  },
  created() {
    this.data = this.customization.data.data.lists;
  },
  methods: {
    editList(item) {
      this.$router.push({
        name: "ListCustomization_Form",
        params: { id: item.id },
      });
    },
    selectItemToDelete(item) {
      this.itemSelected = item;
      this.deleteDialog = true;
    },
    deleteList() {
      var newList = this.data.filter((el) => el.id !== this.itemSelected.id);
      this.customization.data.data.lists = newList;
      this.data = newList;

      // Closing dialog
      this.itemSelected = null;
      this.deleteDialog = false;
    },
  },
};
</script>
<style scoped>
.mx-auto {
  width: 70%;
  margin-top: 2%;
}

@media (max-width: 480px) {
  .mx-auto {
    width: 90%;
  }
}
</style>
