var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    { attrs: { app: "", dark: "", color: "primary" } },
    [
      _c(
        "router-link",
        { attrs: { to: "/" } },
        [
          _c(
            "v-toolbar-title",
            { staticClass: "white--text mr-4" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [_c("span", _vm._g({}, on), [_vm._v(" GEMA ")])]
                      },
                    },
                  ]),
                },
                [_vm._v(" " + _vm._s(_vm.$t("menu.banner_tooltip")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-toolbar-title", [_vm._t("title")], 2),
      _c("v-spacer"),
      _c(
        "v-toolbar-items",
        [
          _vm._t("items"),
          !_vm.userIsLogged
            ? _c(
                "v-menu",
                {
                  attrs: { "open-on-hover": "", "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g({ attrs: { text: "" } }, on),
                              [
                                _vm._v(" " + _vm._s(_vm.$t("language")) + " "),
                                _c("v-icon", [_vm._v("arrow_drop_down")]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1810884660
                  ),
                },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.languages, function (item) {
                      return _c(
                        "v-list-item",
                        {
                          key: item.language,
                          on: {
                            click: function ($event) {
                              return _vm.changeLocale(item.language)
                            },
                          },
                        },
                        [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-menu",
                {
                  attrs: { "open-on-click": "", "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g({ attrs: { icon: "" } }, on),
                            [_c("v-icon", [_vm._v("more_vert")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-group",
                        {
                          attrs: {
                            "no-action": "",
                            "prepend-icon": "language",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function () {
                                return [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.$t("language"))),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        _vm._l(_vm.languages, function (item) {
                          return _c(
                            "v-list-item",
                            {
                              key: item.language,
                              on: {
                                click: function ($event) {
                                  return _vm.changeLocale(item.language)
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.title)),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-list-item",
                        { on: { click: _vm.logout } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("power_settings_new")])],
                            1
                          ),
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("menu.logout_tooltip"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }