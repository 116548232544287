var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-img", {
    attrs: {
      src: _vm.link,
      "aspect-ratio": _vm.aspectRatio,
      height: _vm.height,
      "max-height": _vm.maxHeight,
      contain: _vm.contain,
    },
    on: {
      click: function ($event) {
        return _vm.$emit("click")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "placeholder",
        fn: function () {
          return [
            _c(
              "v-row",
              {
                staticClass: "fill-height ma-0",
                attrs: { align: "center", justify: "center" },
              },
              [
                _c("v-progress-circular", {
                  attrs: { indeterminate: "", color: "lighten-5" },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }