<template>
  <v-img
    :src="link"
    :aspect-ratio="aspectRatio"
    :height="height"
    :max-height="maxHeight"
    :contain="contain"
    @click="$emit('click')"
  >
    <template v-slot:placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular
          indeterminate
          color="lighten-5"
        ></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>

<script>
export default {
  props: {
    img: {
      required: true,
    },
    aspectRatio: {},
    height: {},
    maxHeight: {},
    contain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      link: "",
    };
  },
  watch: {
    img: {
      handler: "src",
      deep: true,
    },
  },
  created() {
    this.src();
  },
  methods: {
    src() {
      if (this.img.file) {
        this.link = this.img.path;
      }
    },
  },
};
</script>
