var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm.data
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        align: "start",
                        justify: "space-around",
                        "no-gutters": "",
                      },
                    },
                    [
                      _c("v-col", { attrs: { cols: "10" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("custom.list.title.list"))),
                        ]),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "pa-3",
                              attrs: {
                                color: "success",
                                to: { name: "ListCustomization_Create" },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("new")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.data,
                      "hide-default-footer": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.id`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "ListCustomization_Form",
                                      params: { id: item.id },
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.id) + " ")]
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-icon",
                                {
                                  attrs: { color: "orange" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editList(item)
                                    },
                                  },
                                },
                                [_vm._v("edit")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  attrs: { color: "red" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectItemToDelete(item)
                                    },
                                  },
                                },
                                [_vm._v("delete ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
              _c("modal-dialog", {
                attrs: {
                  dialog: _vm.deleteDialog,
                  title: _vm.$t("custom.list.delete_title"),
                  titleClass: "error white--text",
                  titleIcon: "warning",
                  submitClass: "error",
                  submitText: _vm.$t("delete"),
                  content: _vm.$t("custom.list.delete_dialog"),
                },
                on: {
                  cancel: function ($event) {
                    _vm.deleteDialog = false
                  },
                  submit: function ($event) {
                    return _vm.deleteList()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }