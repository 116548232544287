var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    attrs: {
      dense: "",
      type: "text",
      label: _vm.label,
      rules: _vm.onlyNumber,
      value: _vm.value,
      disabled: _vm.disabled,
    },
    on: { input: _vm.onInput },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }