import BaseWithoutProject from "./BaseWithoutProject";
import ProjectSelector from "./ProjectSelector";
import Login from "./Login";
import NewProject from "./NewProject";

const routes = [
  {
    path: "/",
    component: BaseWithoutProject,
    children: [
      {
        name: "ProjectSelector",
        path: "",
        component: ProjectSelector,
      },
      {
        name: "Login",
        path: "login",
        component: Login,
        meta: { public: true, isLoginPage: true },
      },
      {
        name: "NewProject",
        path: "new",
        props: true,
        component: NewProject,
      },
    ],
  },
];

export default routes;
