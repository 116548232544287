<template>
  <v-dialog :value="dialog" @click:outside="close" scrollable>
    <v-card>
      <v-card-title>
        <v-row align="center" no-gutters class="white--text">
          <v-col class="text-center">
            {{ $t("custom.list.preview").toUpperCase() }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="4">
            <span class="headline">{{ title }}</span>
          </v-col>
          <v-col cols="12" md="8" class="text-right">
            <v-text-field
              dense
              v-model="search"
              append-icon="search"
              :label="$t('search')"
              single-line
              hide-details
              class="d-inline-block"
              v-if="searching"
            ></v-text-field>

            <v-btn color="primary ml-2" v-if="exportable">
              {{ $t("export") }}
            </v-btn>

            <v-btn color="success ml-2" v-if="form && form.creatable">
              {{ $t("new") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table :headers="headers" :items="items" :search="search">
          <template
            v-for="prop in properties"
            v-slot:[slotName(prop)]="{ item }"
          >
            <a :key="prop.property" v-if="prop.form">
              <u>
                {{ item[prop.property] }}
              </u>
            </a>
            <span :key="prop.property" v-else>
              {{ item[prop.property] }}
            </span>
          </template>
          <template v-slot:[`item.action`]>
            <v-icon color="success"> visibility </v-icon>
            <v-icon color="warning" v-if="form && form.editable"> edit </v-icon>
            <v-icon
              color="error"
              v-if="removeLink"
              @click.stop="showDeleteDialog()"
            >
              delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="text-right">
            <v-btn text @click="close" class="white--text">
              {{ $t("exit") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <modal-dialog
      @cancel="deleteDialog = false"
      @submit="deleteDialog = false"
      :dialog="deleteDialog"
      titleClass="error white--text"
      titleIcon="warning"
      submitClass="error"
      :submitText="$t('delete')"
      :title="$t('delete_title')"
      :content="$t('delete_content')"
    ></modal-dialog>
  </v-dialog>
</template>

<script>
import ModalDialog from "@/components/common/ModalDialog";
import customization from "@/services/customization.service";
export default {
  name: "ListPreview",
  props: {
    properties: {
      type: Array,
      required: true,
    },
    exportable: {
      type: Boolean,
      required: true,
    },
    removeLink: {
      type: Boolean,
      required: true,
    },
    searching: {
      type: Boolean,
      required: true,
    },
    form: {
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      headers: [],
      items: [],
      enums: [],
      customization,
      deleteDialog: false,
    };
  },
  components: { ModalDialog },
  created() {
    this.enums = this.customization.data.data.dataModel.enums;
    let item = {};
    this.properties.forEach((prop) => {
      //Build headers
      this.headers.push({
        text: prop.property,
        value: prop.property,
      });
      //Fill example data in data table
      item[prop.property] = this.fillExampleProp(prop);
    });
    this.headers.push({ text: "", sortable: false, value: "action" });

    this.items.push(item);
  },
  methods: {
    close() {
      this.$emit("exit");
    },
    showDeleteDialog() {
      this.deleteDialog = true;
    },
    fillExampleProp(prop) {
      if (this.isEnum(prop)) {
        return this.randomEnumValue(prop);
      }
      switch (prop.class) {
        case "DateTime":
          return new Date().toLocaleString();
        case "Date":
          return new Date().toLocaleDateString();
        default:
          return "A " + prop.property;
      }
    },
    isEnum(property) {
      return this.enums.find((el) => el.name == property.class);
    },
    randomEnumValue(property) {
      const index = this.enums.findIndex((el) => el.name == property.class);
      let enumerated = this.enums[index];
      return enumerated.values[
        Math.floor(Math.random() * enumerated.values.length)
      ];
    },
    slotName(property) {
      return "item." + property.property;
    },
  },
};
</script>
<style scoped>
.v-card__title {
  background-color: #1976d2;
}
.v-card__actions {
  background-color: #1976d2;
}
</style>
