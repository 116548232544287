import "leaflet";
import "leaflet/dist/leaflet.css";

import iconDefault from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import retinaUrl from "leaflet/dist/images/marker-icon-2x.png";

import { Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: retinaUrl,
  iconUrl: iconDefault,
  shadowUrl: iconShadow,
});

import "leaflet-control-geocoder/dist/Control.Geocoder";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";

import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import "leaflet-extra-markers/dist/js/leaflet.extra-markers";

import "leaflet-draw/dist/leaflet.draw";
import "leaflet-draw/dist/leaflet.draw.css";

import "leaflet.fullscreen/Control.FullScreen";
import "leaflet.fullscreen/Control.FullScreen.css";

import "leaflet-easybutton/src/easy-button";
import "leaflet-easybutton/src/easy-button.css";
