const routes = [
  {
    path: "statics",
    name: "Static_PageList",
    component: () => import("./Static_PageList"),
    meta: { projectSelected: true },
  },
  {
    path: "statics/:id/form",
    name: "Static_PageForm",
    component: () => import("./Static_PageForm"),
    meta: { projectSelected: true },
  },
  {
    path: "statics/new",
    name: "Static_PageCreate",
    component: () => import("./Static_PageForm"),
    meta: { projectSelected: true },
  },
  {
    path: "statics/:id",
    name: "Static_PageDetail",
    component: () => import("./Static_PageDetail"),
    meta: { projectSelected: true },
  },
];

export default routes;
