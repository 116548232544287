<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col>
            <span class="headline">{{ $t("custom.list.title.form") }}</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn class="ml-2" :to="{ name: 'ListCustomization_List' }">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              class="primary ml-2"
              :disabled="!validForm"
              @click="() => (dialog = !dialog)"
            >
              {{ $t("preview") }}
            </v-btn>
            <v-btn class="success ml-2" @click="save" :disabled="!validForm">
              {{ $t("save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="validForm">
          <v-row>
            <v-col cols="12" lg="6">
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    :label="$t('custom.list.listId')"
                    v-model="listElement.id"
                    :rules="[(v) => !!v || $t('validation.required')]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    dense
                    v-model="listElement.entity"
                    @change="reloadProperties(false)"
                    :items="entitiesDisp"
                    item-text="name"
                    :label="$tc('entity.name', 1)"
                    :rules="[(v) => !!v || $t('validation.required')]"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    dense
                    :disabled="!formLinkSelected"
                    v-model="listElement.form"
                    clearable
                    @click:clear="clear()"
                    :items="formsDisp"
                    item-text="id"
                    return-object
                    :label="$t('custom.list.form')"
                  >
                    <template v-if="!formLinkSelected" v-slot:append>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-help-circle</v-icon>
                        </template>
                        <span>{{
                          $t("custom.list.mandatory_feature", {
                            param: $t("GUI_L_FormLink.label"),
                          })
                        }}</span>
                      </v-tooltip>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    id="filter"
                    dense
                    :disabled="!filterableSelected"
                    :label="$t('custom.list.staticFilter')"
                  >
                    <template v-if="!filterableSelected" v-slot:append>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-help-circle</v-icon>
                        </template>
                        <span>{{
                          $t("custom.list.mandatory_feature", {
                            param: $t("GUI_L_Filterable.label"),
                          })
                        }}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                  <v-checkbox
                    dense
                    :disabled="!removableSelected"
                    class="checkbox-dense"
                    v-model="listElement.removeLink"
                    :label="$t('custom.list.linkRemove')"
                  >
                    <template v-if="!removableSelected" v-slot:append>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-help-circle</v-icon>
                        </template>
                        <span>{{
                          $t("custom.list.mandatory_feature", {
                            param: $t("GUI_F_Removable.label"),
                          })
                        }}</span>
                      </v-tooltip>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="6" md="3">
                  <v-checkbox
                    dense
                    :disabled="!sortableSelected"
                    class="checkbox-dense"
                    v-model="listElement.sorting"
                    :label="$t('custom.list.sorting')"
                  >
                    <template v-if="!sortableSelected" v-slot:append>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-help-circle</v-icon>
                        </template>
                        <span>{{
                          $t("custom.list.mandatory_feature", {
                            param: $t("GUI_L_Sortable.label"),
                          })
                        }}</span>
                      </v-tooltip>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="6" md="3">
                  <v-checkbox
                    dense
                    :disabled="!basicSearchSelected"
                    class="checkbox-dense"
                    v-model="listElement.searching"
                    :label="$t('custom.list.searching')"
                    @change="updateSearching()"
                  >
                    <template v-if="!basicSearchSelected" v-slot:append>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-help-circle</v-icon>
                        </template>
                        <span>{{
                          $t("custom.list.mandatory_feature", {
                            param: $t("GUI_L_F_BasicSearch.label"),
                          })
                        }}</span>
                      </v-tooltip>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="6" md="3">
                  <v-checkbox
                    dense
                    :disabled="!rowFilterSelected"
                    class="checkbox-dense"
                    @change="updateFiltering()"
                    v-model="listElement.filtering"
                    :label="$t('custom.list.filtering')"
                  >
                    <template v-if="!rowFilterSelected" v-slot:append>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-help-circle</v-icon>
                        </template>
                        <span>{{
                          $t("custom.list.mandatory_feature", {
                            param: $t("GUI_L_F_RowFilter.label"),
                          })
                        }}</span>
                      </v-tooltip>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    dense
                    v-model="listElement.instructions"
                    :label="$t('custom.list.instructions')"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-col>

            <v-divider vertical></v-divider>

            <v-col cols="12" lg="6">
              <div class="title text-center mb-2">
                {{ $tc("property.name", 2) }}
              </div>
              <v-data-table
                dense
                :headers="headers"
                :items="propertiesDisp"
                hide-default-footer
              >
                <template v-slot:[`item.selected`]="{ item }">
                  <v-checkbox
                    class="selected-check"
                    v-model="item.selected"
                    @change="selectProperty($event, item)"
                  >
                  </v-checkbox>
                </template>

                <template v-slot:[`item.form`]="{ item }">
                  <v-select
                    class="selected-form"
                    v-if="item.posibleForms && formLinkSelected"
                    v-model="item.form"
                    item-text="id"
                    :items="item.posibleForms"
                    :disabled="!item.selected"
                  ></v-select>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <list-preview
      v-if="dialog"
      :dialog="dialog"
      :searching="listElement.searching"
      :exportable="exportSelected"
      :properties="selectedProperties"
      :removeLink="listElement.removeLink"
      :form="listElement.form"
      :title="listElement.id"
      @exit="dialog = false"
    >
    </list-preview>
  </v-container>
</template>

<script>
import customization from "@/services/customization.service";
import engineStore from "@/services/engineStore";
import ListPreview from "./ListPreview";
const geographicClasses = [
  "Point",
  "MultiPoint",
  "LineString",
  "MultiLineString",
  "Polygon",
  "MultiPolygon",
  "Geometry",
];

export default {
  data() {
    return {
      engineStore,
      validForm: false,
      customization,
      listElement: {},
      headers: [
        { text: this.$tc("property.name", 1), value: "property" },
        {
          text: this.$t("custom.list.selected"),
          value: "selected",
          sortable: false,
        },
        {
          text: this.$t("custom.list.form"),
          value: "form",
          sortable: false,
        },
      ],
      propertiesDisp: [],
      entitiesDisp: [],
      formsDisp: [],
      dialog: false,
    };
  },
  components: { ListPreview },
  computed: {
    sortableSelected() {
      return this.engineStore.selection.indexOf("GUI_L_Sortable") != -1;
    },
    filterableSelected() {
      return this.engineStore.selection.indexOf("GUI_L_Filterable") != -1;
    },
    rowFilterSelected() {
      return this.engineStore.selection.indexOf("GUI_L_F_RowFilter") != -1;
    },
    basicSearchSelected() {
      return this.engineStore.selection.indexOf("GUI_L_F_BasicSearch") != -1;
    },
    formLinkSelected() {
      return this.engineStore.selection.indexOf("GUI_L_FormLink") != -1;
    },
    removableSelected() {
      return this.engineStore.selection.indexOf("GUI_F_Removable") != -1;
    },
    exportSelected() {
      return this.engineStore.selection.indexOf("GUI_L_Export") != -1;
    },
    selectedProperties() {
      return this.propertiesDisp.filter((prop) => prop.selected);
    },
  },
  created() {
    const especData = this.customization.data;
    this.entitiesDisp = especData.data.dataModel.entities;
    this.formsDisp = especData.data.forms;

    if (this.$route.params.id && this.$route.params.id != "new") {
      this.listElement = JSON.parse(
        JSON.stringify(
          especData.data.lists.find((el) => el.id == this.$route.params.id)
        )
      );
      if (this.listElement.form) {
        this.listElement.form = JSON.parse(
          JSON.stringify(
            especData.data.forms.find((el) => {
              return el.id == this.listElement.form;
            })
          )
        );
      }
      this.reloadProperties(true);
    } else {
      this.listElement = {
        properties: [],
        searching: false,
        removeLink: false,
      };
      this.propertiesDisp = [];
    }

    this.formsDisp = especData.data.forms.filter((el) => {
      return el.entity == this.listElement.entity;
    });
  },
  methods: {
    showPreview() {
      this.dialog = true;
    },
    updateSearching() {
      if (this.listElement.searching) this.listElement.filtering = false;
    },
    updateFiltering() {
      if (this.listElement.filtering) this.listElement.searching = false;
    },
    selectProperty(selected, item) {
      if (selected) {
        this.listElement.properties.push(item);
      } else {
        this.listElement.properties = this.listElement.properties.filter(
          (el) => el.property !== item.property
        );
      }
    },
    clear() {
      this.listElement.form = null;
    },
    /**
     * Recarga la lista de propiedades
     */
    reloadProperties(firstTime) {
      if (!firstTime) {
        this.listElement.properties = [];
      }
      // Cambiamos la lista de formularios disponibles
      this.formsDisp = this.customization.data.data.forms.filter((el) => {
        return el.entity == this.listElement.entity;
      });

      // Traemos todas las propiedades que tiene la entidad
      const entityProps = this.customization
        .getProperties(this.listElement.entity)
        .map((el) => {
          return { name: el.name, class: el.class };
        })
        .filter((p) => {
          return geographicClasses.indexOf(p.class) == -1;
        });

      // Miramos que existan en la lista actual de propiedades
      entityProps.forEach((el) => {
        el.selected = false;
        var res = this.listElement.properties.find(
          (el1) => el.name === el1.property
        );
        if (res != null) {
          el.selected = true;
          el.property = res.property;
          el.form = res.form;
        } else {
          el.property = el.name;
          el.form = false;
        }
      });

      // Añadimos datos de posibles formularios para las propiedades
      entityProps.forEach((el) => {
        if (el.form) {
          el.posibleForms = this.customization.getForms(el.form.split(" ")[0]);
        }
      });

      this.propertiesDisp = entityProps;

      if (
        this.listElement.form &&
        this.listElement.form.entity != this.listElement.entity
      )
        this.clear();
    },
    save() {
      if (this.validateData()) {
        if (this.$route.params.id && this.$route.params.id != "new") {
          let index = this.customization.data.data.lists.findIndex(
            (list) => list.id == this.$route.params.id
          );
          this.customization.data.data.lists[index] = this.listElement;
          if (this.listElement.form) {
            this.customization.data.data.lists[index].form =
              this.listElement.form.id;
          }
          this.$router.push({ name: "ListCustomization_List" });
        } else {
          if (this.listElement.form)
            this.listElement.form = this.listElement.form.id;
          this.customization.data.data.lists.push(this.listElement);
          this.$router.push({ name: "ListCustomization_List" });
        }
      }
    },
    validateData() {
      if (this.listElement.properties.length < 1) {
        this.$notify({
          type: "error",
          text: this.$t("custom.list.no_props_selected"),
        });
        return false;
      }
      if (this.$route.params.id && this.$route.params.id != "new") {
        if (
          this.listElement.id != this.$route.params.id &&
          this.customization.data.data.lists.findIndex(
            (list) => list.id == this.listElement.id
          ) != -1
        ) {
          this.$notify({
            type: "error",
            text: this.$t("custom.list.duplicated"),
          });
          return false;
        }
      } else if (
        this.customization.data.data.lists.findIndex(
          (list) => list.id == this.listElement.id
        ) != -1
      ) {
        this.$notify({
          type: "error",
          text: this.$t("custom.list.duplicated"),
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.selected-check {
  height: 32px;
  margin-top: 0px;
}

.selected-form {
  padding-top: 0px;
  margin-top: 0px;
}

.selected-form .v-text-field__details {
  display: none;
}

.checkbox-dense {
  margin-top: 0px;
  pointer-events: all;
}

.v-input--is-disabled:not(.v-input--is-readonly) {
  pointer-events: all !important;
}

@media (min-width: 1264px) {
  .col-lg-6 {
    max-width: 49%;
  }
}

.theme--light.v-data-table tbody tr.v-data-table__selected {
  background-color: #ffffff;
}
</style>
