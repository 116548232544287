<template>
  <v-app>
    <notifications
      :max="3"
      :width="400"
      position="top center"
      classes="my-style mt-3"
    >
    </notifications>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import engineStore from "@/services/engineStore";
import store from "@/services/store";

export default {
  name: "App",
  data() {
    return {
      engineStore,
      store,
    };
  },
};
</script>

<style lang="scss">
.my-style {
  padding: 10px;

  font-size: 16px;

  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }

  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }
}

.joint-paper .labels text {
  font-size: 10px;
}
</style>
