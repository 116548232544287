<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title>
          <v-row align="center" justify="space-between" no-gutters>
            <v-col>
              <span class="headline">{{ $t("custom.form.title.form") }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="text-right">
              <v-btn class="ml-2" :to="{ name: 'FormCustomization_List' }">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                class="primary ml-2"
                :disabled="!validForm"
                @click="() => (dialog = !dialog)"
              >
                {{ $t("preview") }}
              </v-btn>
              <v-btn class="success ml-2" @click="save" :disabled="!validForm">
                {{ $t("save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form v-model="validForm">
            <v-row>
              <v-col cols="12" lg="6">
                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      :label="$t('custom.form.formId')"
                      v-model="formElement.id"
                      :rules="[(v) => !!v || $t('validation.required')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      dense
                      v-model="formElement.entity"
                      :items="entitiesDisp"
                      @change="reloadProperties()"
                      item-text="name"
                      :label="$tc('entity.name', 1)"
                      :rules="[(v) => !!v || $t('validation.required')]"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      dense
                      :disabled="!creatableSelected"
                      class="checkbox-dense"
                      v-model="formElement.creatable"
                      @change="updateEditable($event)"
                      :label="$t('custom.form.creatable')"
                    >
                      <template v-if="!creatableSelected" v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle</v-icon>
                          </template>
                          <span>{{
                            $t("custom.form.mandatory_feature", {
                              param: $t("GUI_F_Creatable.label"),
                            })
                          }}</span>
                        </v-tooltip>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      dense
                      :disabled="!editableSelected"
                      class="checkbox-dense"
                      v-model="formElement.editable"
                      @change="updateEditable($event)"
                      :label="$t('custom.form.editable')"
                    >
                      <template v-if="!editableSelected" v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle</v-icon>
                          </template>
                          <span>{{
                            $t("custom.form.mandatory_feature", {
                              param: $t("GUI_F_Editable.label"),
                            })
                          }}</span>
                        </v-tooltip>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      dense
                      :disabled="!removableSelected"
                      class="checkbox-dense"
                      v-model="formElement.removable"
                      :label="$t('custom.form.removable')"
                      @change="updateRemovable()"
                    >
                      <template v-if="!removableSelected" v-slot:append>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle</v-icon>
                          </template>
                          <span>{{
                            $t("custom.form.mandatory_feature", {
                              param: $t("GUI_F_Removable.label"),
                            })
                          }}</span>
                        </v-tooltip>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4" offset="8">
                    <v-checkbox
                      dense
                      :disabled="
                        !formElement.removable || !confirmationAlertSelected
                      "
                      class="checkbox-dense"
                      v-model="formElement.confirmation"
                      :label="$t('custom.form.confirmRemoval')"
                    >
                      <template
                        v-if="
                          !formElement.removable || !confirmationAlertSelected
                        "
                        v-slot:append
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-help-circle</v-icon>
                          </template>
                          <span>{{
                            $t("custom.form.mandatory_feature", {
                              param: $t("GUI_F_R_ConfirmationAlert.label"),
                            })
                          }}</span>
                        </v-tooltip>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      dense
                      v-model="formElement.instructions"
                      :label="$t('custom.form.instructions')"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>

              <v-divider vertical></v-divider>

              <v-col cols="12" lg="6">
                <div class="title text-center mb-2">
                  {{ $tc("property.name", 2) }}
                </div>
                <v-data-table
                  dense
                  :headers="headers"
                  :items="propertiesDisp"
                  hide-default-footer
                >
                  <template v-slot:[`item.editing`]="{ item }">
                    <v-checkbox
                      @change="updatePropEditing(item)"
                      class="selected-check"
                      :disabled="editingDisabled || item.property == 'id'"
                      v-model="item.editing"
                    ></v-checkbox>
                  </template>

                  <template v-slot:[`item.viewing`]="{ item }">
                    <v-checkbox
                      @change="updatePropViewing(item)"
                      class="selected-check"
                      v-model="item.viewing"
                    ></v-checkbox>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
    <view-selector
      :visibility="formElement.properties"
      :visibleProperties="visibleProperties"
      :editableProperties="editableProperties"
      :name="formElement.id"
      :dialog="dialog"
      :editable="formElement.editable"
      :creatable="formElement.creatable"
      :removable="formElement.removable"
      @exit="() => (dialog = false)"
    >
    </view-selector>
  </div>
</template>

<script>
import customization from "@/services/customization.service";
import engineStore from "@/services/engineStore";
import ViewSelector from "./ViewSelector";

export default {
  data() {
    return {
      engineStore,
      validForm: false,
      customization,
      formElement: {},
      headers: [
        { text: this.$tc("property.name", 1), value: "property" },
        {
          text: this.$t("custom.form.view"),
          value: "viewing",
          sortable: false,
        },
        {
          text:
            this.$t("custom.form.edit") + "/" + this.$t("custom.form.create"),
          value: "editing",
          sortable: false,
        },
      ],
      entitiesDisp: [],
      propertiesDisp: [],
      editingDisabled: false,
      entityProperties: [],
      dialog: false,
    };
  },
  components: { ViewSelector },
  computed: {
    creatableSelected() {
      return this.engineStore.selection.indexOf("GUI_F_Creatable") != -1;
    },
    editableSelected() {
      return this.engineStore.selection.indexOf("GUI_F_Editable") != -1;
    },
    removableSelected() {
      return this.engineStore.selection.indexOf("GUI_F_Removable") != -1;
    },
    confirmationAlertSelected() {
      return (
        this.engineStore.selection.indexOf("GUI_F_R_ConfirmationAlert") != -1
      );
    },
    visibleProperties() {
      if (this.entityProperties != [] && this.propertiesDisp != [])
        return this.entityProperties.filter(
          (prop) =>
            this.propertiesDisp.find((el) => el.property == prop.name).viewing
        );
      else return [];
    },
    editableProperties() {
      if (this.entityProperties != [] && this.propertiesDisp != [])
        return this.entityProperties.filter(
          (prop) =>
            this.propertiesDisp.find((el) => el.property == prop.name).editing
        );
      else return [];
    },
  },
  created() {
    const especData = JSON.parse(JSON.stringify(this.customization.data));
    this.entitiesDisp = especData.data.dataModel.entities;

    if (this.$route.params.id && this.$route.params.id != "new") {
      this.formElement = especData.data.forms.find(
        (el) => el.id == this.$route.params.id
      );
      this.formElement.properties = JSON.parse(
        JSON.stringify(
          especData.data.forms.find((el) => el.id == this.$route.params.id)
            .properties
        )
      );
      this.propertiesDisp = JSON.parse(
        JSON.stringify(this.formElement.properties)
      );
      this.entityProperties = JSON.parse(
        JSON.stringify(
          this.entitiesDisp
            .find((el) => el.name == this.formElement.entity)
            .properties.filter((prop) =>
              this.propertiesDisp.find((el) => el.property == prop.name)
            )
        )
      );
    } else {
      this.formElement = {};
      especData.data.forms.push(this.formElement);
      this.propertiesDisp = [];
    }

    if (!this.formElement.editable) {
      this.editingDisabled = true;
    }
  },
  methods: {
    updatePropEditing(item) {
      let index = this.formElement.properties.findIndex(
        (el) => el.property == item.property
      );
      this.formElement.properties[index].editing = item.editing;
    },
    updatePropViewing(item) {
      let index = this.formElement.properties.findIndex(
        (el) => el.property == item.property
      );
      this.formElement.properties[index].viewing = item.viewing;
    },
    updateRemovable() {
      if (this.formElement.removable == false) {
        this.formElement.confirmation = false;
      }
    },
    updateEditable() {
      if (!this.formElement.creatable && !this.formElement.editable) {
        this.editingDisabled = true;
        this.formElement.properties.forEach((el) => {
          if (el.editing) el.editing = false;
        });
        this.propertiesDisp.forEach((el) => {
          if (el.editing) el.editing = false;
        });
      } else {
        this.editingDisabled = false;
      }
    },
    reloadProperties() {
      var entityProps = this.customization.getProperties(
        this.formElement.entity
      );

      this.formElement.properties = [];

      entityProps.forEach((el) => {
        let prop = {};
        prop.editing = false;
        prop.viewing = false;
        prop.property = el.name;
        this.formElement.properties.push(prop);
      });

      this.entityProperties = entityProps;

      this.propertiesDisp = [];

      entityProps.forEach((el) => {
        let prop = {};
        prop.editing = false;
        prop.viewing = false;
        prop.property = el.name;
        this.propertiesDisp.push(prop);
      });
    },
    save() {
      if (this.validateData())
        if (this.$route.params.id && this.$route.params.id != "new") {
          let index = this.customization.data.data.forms.findIndex(
            (el) => el.id === this.$route.params.id
          );
          this.customization.data.data.forms[index] = this.formElement;
          this.$router.push({ name: "FormCustomization_List" });
        } else {
          this.customization.data.data.forms.push(this.formElement);
          this.$router.push({ name: "FormCustomization_List" });
        }
    },
    validateData() {
      if (!this.formElement.properties.find((prop) => prop.viewing)) {
        this.$notify({
          type: "error",
          text: this.$t("custom.form.no_props_viewing"),
        });
        return false;
      }
      if (
        this.formElement.editable &&
        !this.formElement.properties.find((prop) => prop.editing)
      ) {
        this.$notify({
          type: "error",
          text: this.$t("custom.form.no_props_editing"),
        });
        return false;
      }
      if (this.$route.params.id && this.$route.params.id != "new") {
        if (
          this.$route.params.id != this.formElement.id &&
          this.customization.data.data.forms.findIndex(
            (el) => el.id === this.formElement.id
          ) != -1
        ) {
          this.$notify({
            type: "error",
            text: this.$t("custom.form.duplicated"),
          });
          return false;
        }
      } else if (
        this.customization.data.data.forms.findIndex(
          (el) => el.id === this.formElement.id
        ) != -1
      ) {
        this.$notify({
          type: "error",
          text: this.$t("custom.form.duplicated"),
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.selected-check {
  height: 32px;
  margin-top: 0px;
}

.checkbox-dense {
  margin-top: 0px;
  pointer-events: all;
}

@media (min-width: 1264px) {
  .col-lg-6 {
    max-width: 49%;
  }
}
</style>
