<template>
  <v-text-field
    dense
    type="text"
    :label="label"
    :rules="onlyNumber"
    :value="value"
    :disabled="disabled"
    @input="onInput"
  ></v-text-field>
</template>

<script>
export default {
  name: "NumberField",
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "Integer",
    },
    disabled: {
      required: false,
      default: false,
    },
    value: {},
  },
  data() {
    return {
      decimalTypes: ["float", "double", "bigdecimal"],
    };
  },
  computed: {
    onlyNumber() {
      if (this.decimalTypes.includes(this.type.toLowerCase()))
        return [
          (v) =>
            !v ||
            /^-?\d*\.?\d*$/.test(v) ||
            this.$t("numberField.validation.decimal"),
        ];
      else
        return [
          (v) =>
            !v || /^\d+$/.test(v) || this.$t("numberField.validation.integer"),
        ];
    },
  },
  methods: {
    onInput(e) {
      this.$emit("input", e);
    },
  },
};
</script>
