import FeatureTree from "./FeatureTree";

const routes = [
  {
    name: "FeatureTree",
    path: "features",
    component: FeatureTree,
  },
];

export default routes;
