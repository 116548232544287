var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c("v-col", { attrs: { cols: "6" } }, [
            _c("div", { attrs: { id: "stencil" } }),
          ]),
          _c(
            "v-col",
            {
              staticClass: "text-right",
              attrs: { cols: "6", "align-self": "end" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  on: {
                    click: function ($event) {
                      return _vm.load()
                    },
                  },
                },
                [
                  _c("v-icon", [_vm._v("refresh")]),
                  _c("label", { staticClass: "d-none d-md-flex" }, [
                    _vm._v(_vm._s(_vm.$t("uml.button.reload"))),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  class: { quadrat: _vm.changes },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [
                  _c("v-icon", [_vm._v("save")]),
                  _c("label", { staticClass: "d-none d-md-flex" }, [
                    _vm._v(_vm._s(_vm.$t("uml.button.save"))),
                  ]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.decreaseHeight()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-arrow-up")])],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.increaseHeight()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-arrow-down")])],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.zoomIn()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("add")])],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.zoomOut()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("remove")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "div",
              { ref: "paperCanvas", attrs: { id: "paper-container" } },
              [_c("div", { attrs: { id: "paper" } })]
            ),
          ]),
        ],
        1
      ),
      _c("modal-entity-form", {
        attrs: { dialog: _vm.entityForm, model: _vm.selectedEntityModel },
        on: {
          close: function ($event) {
            _vm.entityForm = false
          },
          newLink: _vm.newLink,
        },
      }),
      _c("modal-enum-form", {
        attrs: { dialog: _vm.enumForm, model: _vm.selectedEnumModel },
        on: {
          close: function ($event) {
            _vm.enumForm = false
          },
        },
      }),
      _c("modal-relationship-form", {
        attrs: {
          dialog: _vm.relationshipForm,
          model: _vm.selectedRelationshipModel,
          "source-id": _vm.selectedSource,
        },
        on: {
          close: function ($event) {
            _vm.relationshipForm = false
          },
        },
      }),
      _vm.removeAssociationModal
        ? _c("modal-relationship-delete-dialog", {
            attrs: {
              dialog: _vm.removeAssociationModal,
              model: _vm.selectedRelationshipModel,
            },
            on: {
              close: function ($event) {
                _vm.removeAssociationModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }