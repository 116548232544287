var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { persistent: "", value: _vm.dialog, scrollable: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                {
                  staticClass: "white--text",
                  attrs: { align: "center", "no-gutters": "" },
                },
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("gallery.image_form.form")) + " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "mt-4 pb-0" },
            [
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.validForm,
                    callback: function ($$v) {
                      _vm.validForm = $$v
                    },
                    expression: "validForm",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              type: "text",
                              label: _vm.$t("gallery.image_form.title"),
                            },
                            model: {
                              value: _vm.formImage.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.formImage, "title", $$v)
                              },
                              expression: "formImage.title",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              type: "text",
                              label: _vm.$t("gallery.image_form.author"),
                            },
                            model: {
                              value: _vm.formImage.author,
                              callback: function ($$v) {
                                _vm.$set(_vm.formImage, "author", $$v)
                              },
                              expression: "formImage.author",
                            },
                          }),
                          _c("number-field", {
                            attrs: { label: _vm.$t("gallery.image_form.year") },
                            model: {
                              value: _vm.formImage.year,
                              callback: function ($$v) {
                                _vm.$set(_vm.formImage, "year", $$v)
                              },
                              expression: "formImage.year",
                            },
                          }),
                          _c("v-textarea", {
                            staticClass: "pt-2",
                            attrs: {
                              label: _vm.$t("gallery.image_form.description"),
                            },
                            model: {
                              value: _vm.formImage.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.formImage, "description", $$v)
                              },
                              expression: "formImage.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(_vm.$t("gallery.image_form.file")) +
                                      ":"
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-file-input", {
                                    attrs: {
                                      dense: "",
                                      accept: ".jpg,.jpeg,.png,.gif",
                                      "prepend-icon": "mdi-camera",
                                      chips: "",
                                      rules: [
                                        (v) =>
                                          !!(v || _vm.formImage.path) ||
                                          _vm.$t("gallery.image_form.required"),
                                      ],
                                    },
                                    on: { change: _vm.onFileChange },
                                    model: {
                                      value: _vm.formImage.file,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formImage, "file", $$v)
                                      },
                                      expression: "formImage.file",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _vm.formImage.path
                                    ? _c("custom-image", {
                                        attrs: {
                                          img: _vm.formImage,
                                          contain: "",
                                          "max-height": "300",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                {
                  attrs: {
                    align: "center",
                    justify: "space-between",
                    "no-gutters": "",
                  },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$emit("cancel")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-4 success",
                          attrs: { disabled: !_vm.validForm },
                          on: {
                            click: function ($event) {
                              return _vm.save()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }