import { dia, shapes } from "jointjs";

shapes.custom.Inheritance = dia.Link.extend({
  defaults: {
    type: "custom.Inheritance",
    attrs: {
      ".marker-arrowheads": { display: "none" },
    },
    smooth: true,
    source: {},
    toolMarkup: "<g class='connection'></g>",
  },
  initialize: function () {
    // change does not work in this case, updateConnection must be called manually
    this.on(
      "change:bidirectional change:sourceOpts change:targetOpts",
      function () {
        this.updateConnection();
        this.trigger("uml-update");
      },
      this
    );

    this.updateConnection();
    dia.Link.prototype.initialize.apply(this, arguments);
  },

  updateConnection: function () {
    const attrs = this.get("attrs");

    attrs[".marker-target"] = {
      fill: "#ffffff",
      stroke: "#4b4a67",
      d: "M 10 0 L 0 5 L 10 10 V 0 10",
      "fill-opacity": 1,
    };
  },
});
