<template>
  <v-dialog max-width="800px" persistent :value="dialog">
    <v-card>
      <v-card-title primary-title class="headline primary white--text">
        {{ $t("uml.relationship") }}
      </v-card-title>

      <v-card-text>
        <v-form ref="relationshipForm" v-model="validForm">
          <v-container fluid>
            <v-row align="center" dense>
              <v-col cols="6">
                <span class="title">{{ $t("uml.form.source") }}</span>
              </v-col>

              <v-col cols="6">
                <span class="title">{{ $t("uml.form.target") }}</span>
              </v-col>

              <v-col cols="6">
                <v-autocomplete
                  v-model="form.source"
                  :label="$tc('entity.name', 1)"
                  :items="entities"
                  item-text="attributes.name"
                  :rules="[(v) => !!v || $t('validation.required')]"
                ></v-autocomplete>
              </v-col>

              <v-col cols="6">
                <v-autocomplete
                  v-model="form.target"
                  :label="$tc('entity.name', 1)"
                  :items="entities"
                  item-value="attributes"
                  item-text="attributes.name"
                  :rules="[(v) => !!v || $t('validation.required')]"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="form.sourceOpts.label"
                  :label="$t('uml.form.label')"
                  :rules="[
                    (v) => !!v || $t('validation.required'),
                    (v) =>
                      usesRestrictedKeywords(v) ||
                      v + ' ' + $t('uml.form.validation.postgres_keywords'),
                    (v) =>
                      !v ||
                      reservedWords.indexOf(v) === -1 ||
                      $t('uml.form.validation.reserved_words'),
                    (v) =>
                      !v ||
                      v.search(' ') === -1 ||
                      $t('uml.form.validation.space_name'),
                  ]"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="form.targetOpts.label"
                  :label="$t('uml.form.label')"
                  :rules="[
                    (v) => !!v || $t('validation.required'),
                    (v) =>
                      usesRestrictedKeywords(v) ||
                      v + ' ' + $t('uml.form.validation.postgres_keywords'),
                    (v) =>
                      !v ||
                      reservedWords.indexOf(v) === -1 ||
                      $t('uml.form.validation.reserved_words'),
                    (v) =>
                      !v ||
                      v.search(' ') === -1 ||
                      $t('uml.form.validation.space_name'),
                  ]"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-select
                  v-model="form.sourceOpts.multiplicity"
                  :label="$t('uml.form.multiplicity')"
                  :items="possibleMultiplicity"
                  :rules="[(v) => !!v || $t('validation.required')]"
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-select
                  v-model="form.targetOpts.multiplicity"
                  :label="$t('uml.form.multiplicity')"
                  :items="possibleMultiplicity"
                  :rules="[(v) => !!v || $t('validation.required')]"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save()" :disabled="!validForm">
          {{ $t("save") }}
        </v-btn>
        <v-btn class="warning" @click="close()">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import graphHelper from "../graph.helper";
import reservedWords from "@/components/specification/datamodel/ReservedWords";
import { usesRestrictedKeywords } from "./keywords";

export default {
  data() {
    return {
      validForm: false,
      form: { sourceOpts: {}, targetOpts: {} },
      reservedWords,
    };
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    model: {
      validator: (prop) => typeof prop === "object" || prop === null,
      required: true,
    },
    sourceId: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    entities() {
      if (!this.dialog) return [];
      return graphHelper.getEntities().sort((a, b) => {
        if (a.attributes.name < b.attributes.name) return -1;
        if (a.attributes.name > b.attributes.name) return 1;
        return 0;
      });
    },
    possibleMultiplicity() {
      return ["1", "0..1", "0..*", "1..*"];
    },
  },
  watch: {
    model() {
      this._updateData();
    },
    sourceId() {
      this._updateData();
    },
  },
  methods: {
    usesRestrictedKeywords,
    _updateData() {
      if (this.model) {
        this.form = {
          target: this.entities.find(
            (e) => e.id === this.model.attributes.target.id
          ),
          source: this.entities.find(
            (e) => e.id === this.model.attributes.source.id
          ),
          sourceOpts: this.model.attributes.sourceOpts,
          targetOpts: this.model.attributes.targetOpts,
          bidirectional: this.model.attributes.bidirectional,
        };
      } else {
        this.form = {
          source: this.entities.find((e) => e.getId() === this.sourceId),
          target: null,
          sourceOpts: { label: "", multiplicity: "1" },
          targetOpts: { label: "", multiplicity: "1" },
          bidirectional: true,
        };
      }
    },
    save() {
      if (this.model) {
        this.model.set("source", { id: this.form.source.id });
        this.model.set("target", { id: this.form.target.id });
        this.model.set("sourceOpts", this.form.sourceOpts);
        this.model.set("targetOpts", this.form.targetOpts);
        this.model.set("bidirectional", this.form.bidirectional);
        this.model.updateConnection();
      } else {
        graphHelper.addLink({
          source: { id: this.form.source.id },
          target: { id: this.form.target.id },
          sourceOpts: this.form.sourceOpts,
          targetOpts: this.form.targetOpts,
          bidirectional: this.form.bidirectional,
        });
      }
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
