var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { "max-width": "800px", persistent: "", value: _vm.dialog } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "headline primary white--text",
              attrs: { "primary-title": "" },
            },
            [_vm._v(" " + _vm._s(_vm.$tc("uml.enum.name", 1)) + " ")]
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "enumForm",
                  model: {
                    value: _vm.validForm,
                    callback: function ($$v) {
                      _vm.validForm = $$v
                    },
                    expression: "validForm",
                  },
                },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [
                                    (v) => !!v || _vm.$t("validation.required"),
                                    (v) =>
                                      _vm.usesRestrictedKeywords(v) ||
                                      v +
                                        " " +
                                        _vm.$t(
                                          "uml.form.validation.postgres_keywords"
                                        ),
                                    (v) =>
                                      v !== "Enum" ||
                                      _vm.$t("uml.form.validation.enum_name"),
                                    (v) =>
                                      !v ||
                                      v.search(" ") === -1 ||
                                      _vm.$t("uml.form.validation.space_name"),
                                  ],
                                  label: _vm.$t("uml.form.enum_name"),
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _c("span", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.$t("uml.form.values"))),
                            ]),
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "text-right", attrs: { cols: "4" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addValue()
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("add")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._l(_vm.form.values, function (att, index) {
                            return _c(
                              "v-col",
                              {
                                key: index,
                                attrs: { cols: "12", sm: "6", md: "4" },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeValue(att)
                                      },
                                    },
                                  },
                                  [_vm._v(" delete ")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: att.value,
                                      expression: "att.value",
                                    },
                                  ],
                                  ref: index,
                                  refInFor: true,
                                  attrs: {
                                    type: "text",
                                    placeholder: _vm.$t("uml.form.enum_value"),
                                  },
                                  domProps: { value: att.value },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        att,
                                        "value",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.formIsValid },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "warning",
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "error" },
                  on: {
                    click: function ($event) {
                      _vm.deleteEnumDialog = true
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-dialog", {
        attrs: {
          dialog: _vm.deleteEnumDialog,
          title: _vm.$t("uml.dialog.enum.delete_title"),
          titleClass: "error white--text",
          titleIcon: "warning",
          submitClass: "error",
          submitText: _vm.$t("delete"),
          content: _vm.$t("uml.dialog.enum.delete_dialog"),
        },
        on: {
          cancel: function ($event) {
            _vm.deleteEnumDialog = false
          },
          submit: function ($event) {
            return _vm.removeEnum()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }