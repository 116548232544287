var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialog
    ? _c(
        "v-dialog",
        {
          attrs: { value: _vm.dialog, scrollable: "" },
          on: { "click:outside": _vm.close },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-tabs",
                {
                  attrs: { grow: "", "background-color": "primary" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", [_vm._v(" " + _vm._s(_vm.$t("detail")) + " ")]),
                  _vm.creatable || _vm.editable
                    ? _c("v-tab", [
                        _vm._v(" " + _vm._s(_vm.$t(_vm.options)) + " "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c(
                        "v-tab-item",
                        [
                          _vm.tab == 0
                            ? _c("detail-preview", {
                                attrs: {
                                  properties: _vm.visibleProperties,
                                  visibility: _vm.visibility,
                                  name: _vm.name,
                                  removable: _vm.removable,
                                  editable: _vm.editable,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _vm.tab == 1
                            ? _c("form-preview", {
                                attrs: {
                                  properties: _vm.editableProperties,
                                  editability: _vm.visibility,
                                  name: _vm.name,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        align: "center",
                        justify: "space-between",
                        "no-gutters": "",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: { text: "" },
                              on: { click: _vm.close },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("exit")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }