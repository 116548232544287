var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal-dialog", {
    attrs: {
      titleClass: "error white--text",
      titleIcon: "warning",
      submitClass: "error",
      submitText: _vm.$t("delete"),
      "submit-text": _vm.$t("delete"),
      dialog: _vm.dialog,
      title: _vm.$t("uml.dialog.relationship.delete_title"),
      content: _vm.$t("uml.dialog.relationship.delete_dialog", {
        source: _vm.source,
        srcLabel: _vm.srcLabel,
        target: _vm.target,
        tgtLabel: _vm.tgtLabel,
      }),
    },
    on: {
      cancel: function ($event) {
        return _vm.$emit("close")
      },
      submit: _vm.remove,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }