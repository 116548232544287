<template>
  <v-dialog max-width="800px" persistent :value="dialog">
    <v-card>
      <v-card-title primary-title class="headline primary white--text">
        {{ $tc("entity.name", 1) }}
      </v-card-title>

      <v-card-text>
        <v-form ref="entityForm" v-model="validForm">
          <v-container fluid>
            <v-row align="center" no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  :label="$t('uml.form.entity_name')"
                  :rules="[
                    (v) => !!v || $t('validation.required'),
                    (v) =>
                      usesRestrictedKeywords(v) ||
                      v + ' ' + $t('uml.form.validation.postgres_keywords'),
                    (v) =>
                      v !== 'Class' || $t('uml.form.validation.class_name'),
                    (v) =>
                      !v ||
                      v.search(' ') === -1 ||
                      $t('uml.form.validation.space_name'),
                  ]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" no-gutters>
              <v-col cols="12">
                <v-select
                  v-model="form.superclass"
                  clearable
                  :label="$t('uml.form.superclass')"
                  :items="possibleSuperclasses"
                  item-text="name"
                  return-object
                  @change="checkInheritedId()"
                ></v-select>
                <v-checkbox
                  v-model="form.abstract"
                  :label="$t('uml.form.abstract')"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <span class="title">{{ $tc("property.name", 2) }}</span>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-btn icon @click="addProperty()">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("uml.form.property_name") }}
                  </th>
                  <th class="text-left">
                    {{ $t("uml.form.class") }}
                  </th>
                  <th>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span class="text-center d-inline-flex">
                          {{ $t("uml.form.display_string") }}
                          <v-icon v-on="on" small>help</v-icon>
                        </span>
                      </template>
                      {{ $t("uml.form.display_string_tooltip") }}
                    </v-tooltip>
                  </th>
                  <th class="text-left">
                    {{ $t("uml.form.required") }}
                  </th>
                  <th>{{ $t("uml.form.constraints") }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(property, index) in form.properties">
                  <tr :key="index" align="center">
                    <td>
                      <v-text-field
                        v-model="property.name"
                        class="mb-2"
                        dense
                        :disabled="property.pk"
                        flat
                        hide-details="auto"
                        :placeholder="$t('uml.form.property_name')"
                        :rules="[
                          (v) =>
                            !v ||
                            v.search(' ') === -1 ||
                            $t('uml.form.validation.space_name'),
                          (v) =>
                            usesRestrictedKeywords(v) ||
                            v +
                              ' ' +
                              $t('uml.form.validation.postgres_keywords'),
                        ]"
                      />
                    </td>

                    <td>
                      <select
                        v-model="property.class"
                        :disabled="property.pk"
                        @change="classChange(property)"
                      >
                        <option v-for="item in possibleClasses" :key="item">
                          {{ item }}
                        </option>
                      </select>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        v-model="property.displayString"
                        v-if="possibleDisplayString(property)"
                        @change="setDisplayString(property)"
                      />
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        v-model="property.required"
                        :disabled="property.pk"
                      />
                    </td>

                    <td>
                      <div v-show="!property.pk" style="margin-top: 3px">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <input
                                type="checkbox"
                                value="1"
                                :checked="hasConstraints(property)"
                                readonly
                              />
                            </span>
                          </template>
                          <span v-show="hasConstraints(property)">
                            {{ $t("uml.form.hasConstraints.true") }}
                          </span>
                          <span v-show="!hasConstraints(property)">
                            {{ $t("uml.form.hasConstraints.false") }}
                          </span>
                        </v-tooltip>
                        <v-icon
                          class="pointer icon-align"
                          @click="handleRequirements(index)"
                          v-show="selectedRow !== index"
                        >
                          arrow_drop_down
                        </v-icon>
                        <v-icon
                          class="pointer icon-align"
                          @click="handleRequirements(index)"
                          v-show="selectedRow === index"
                        >
                          arrow_drop_up
                        </v-icon>
                      </div>
                    </td>

                    <td>
                      <v-icon
                        class="pointer"
                        v-show="!property.pk"
                        @click="showRemoveProperty(property)"
                        >delete</v-icon
                      >
                    </td>
                  </tr>

                  <tr
                    :key="'A' + index"
                    v-show="selectedRow === index"
                    class="accordion"
                  >
                    <td colspan="6">
                      <v-row no-gutters>
                        <v-col cols="12" class="text-center">
                          <span
                            ><b>{{ $t("uml.form.constraints") }}</b></span
                          >
                        </v-col>

                        <v-col cols="12">
                          <input
                            :id="'unique' + index"
                            type="checkbox"
                            v-model="property.unique"
                            :disabled="property.pk"
                          />
                          <label :for="'unique' + index">{{
                            $t("uml.form.unique")
                          }}</label>
                        </v-col>

                        <template
                          v-if="
                            property.class === 'String' ||
                            property.class === 'Text'
                          "
                        >
                          <v-col cols="12" md="3">
                            <input
                              :id="'length' + index"
                              type="checkbox"
                              v-model="property.lengthConstraint"
                              @change="handleLength(property)"
                            />
                            <label :for="'length' + index">{{
                              $t("uml.form.length")
                            }}</label>
                          </v-col>

                          <v-col cols="12" md="9" class="text-center">
                            <input
                              :id="'min' + index"
                              :ref="'min' + index"
                              type="number"
                              min="0"
                              :disabled="!property.lengthConstraint"
                              v-model="property.min"
                              :placeholder="$t('uml.form.min')"
                              @input="checkLength($event, property, index)"
                            />

                            <input
                              :id="'max' + index"
                              :ref="'max' + index"
                              type="number"
                              min="1"
                              :disabled="!property.lengthConstraint"
                              v-model="property.max"
                              :placeholder="$t('uml.form.max')"
                              @input="checkLength($event, property, index)"
                            />
                          </v-col>

                          <v-col cols="4">
                            <label for="patternType">
                              {{ $t("uml.form.pattern") }} :
                            </label>
                            <select
                              :id="'patternType' + index"
                              v-model="property.patternType"
                            >
                              <option
                                v-for="item in possiblePatterns"
                                :key="item.value"
                                :value="item.value"
                              >
                                {{ item.label }}
                              </option>
                            </select>
                          </v-col>

                          <v-col
                            cols="8"
                            class="text-center"
                            v-if="property.patternType == 'customPattern'"
                          >
                            <input
                              required
                              type="text"
                              v-model="property.pattern"
                              style="width: 100%"
                              :placeholder="
                                $t('uml.form.customPattern.placeholder')
                              "
                            />
                          </v-col>
                        </template>

                        <template
                          v-if="isNumber(property.class) && !property.pk"
                        >
                          <v-col cols="12" md="3">
                            <input
                              :id="'value' + index"
                              type="checkbox"
                              v-model="property.valueConstraint"
                              @change="handleValue(property)"
                            />
                            <label :for="'value' + index">{{
                              $t("uml.form.values")
                            }}</label>
                          </v-col>

                          <v-col cols="12" md="9" class="text-center">
                            <input
                              :id="'minValue' + index"
                              :ref="'minValue' + index"
                              type="number"
                              min="0"
                              :disabled="!property.valueConstraint"
                              v-model="property.min"
                              :placeholder="$t('uml.form.min')"
                              @input="checkValue($event, property, index)"
                            />

                            <input
                              :id="'maxValue' + index"
                              :ref="'maxValue' + index"
                              type="number"
                              min="1"
                              :disabled="!property.valueConstraint"
                              v-model="property.max"
                              :placeholder="$t('uml.form.max')"
                              @input="checkValue($event, property, index)"
                            />
                          </v-col>
                        </template>
                      </v-row>
                    </td>
                  </tr>
                </template>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save()" :disabled="!formIsValid">
          {{ $t("save") }}
        </v-btn>
        <v-btn color="primary" @click="save(true)" :disabled="!formIsValid">
          {{ $t("uml.button.save_add_relationship") }}
        </v-btn>
        <v-btn class="warning" @click="close()">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="error" @click="deleteEntityDialog = true">
          {{ $t("remove") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- dialog delete entity -->
    <modal-dialog
      @cancel="deleteEntityDialog = false"
      @submit="removeEntity()"
      :dialog="deleteEntityDialog"
      :title="$t('uml.dialog.entity.delete_title')"
      titleClass="error white--text"
      titleIcon="warning"
      submitClass="error"
      :submitText="$t('delete')"
      :content="$t('uml.dialog.entity.delete_dialog')"
    ></modal-dialog>

    <!-- dialog delete property -->
    <modal-dialog
      @cancel="deletePropertyDialog = false"
      @submit="removeProperty(selectedProperty)"
      :dialog="deletePropertyDialog"
      :title="$t('uml.dialog.property.delete_title')"
      titleClass="error white--text"
      titleIcon="warning"
      submitClass="error"
      :submitText="$t('delete')"
      :content="$t('uml.dialog.property.delete_dialog')"
    ></modal-dialog>

    <!-- dialog delete property -->
    <modal-dialog
      @cancel="deleteChildPropertyDialog = false"
      @submit="removeChildProperty"
      :dialog="deleteChildPropertyDialog"
      :title="$t('uml.dialog.property.delete_title')"
      titleClass="error white--text"
      titleIcon="warning"
      submitClass="error"
      :submitText="$t('delete')"
      :content="$t('uml.notification.child_properties')"
    ></modal-dialog>
  </v-dialog>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import engineStore from "@/services/engineStore";
import graphHelper from "../graph.helper";
import ModalDialog from "@/components/common/ModalDialog";
import { usesRestrictedKeywords } from "./keywords";

export default {
  components: { ModalDialog },
  data() {
    return {
      validForm: false,
      deleteChildPropertyDialog: false,
      deleteEntityDialog: false,
      deletePropertyDialog: false,
      selectedProperty: null,
      selectedRow: null,
      possiblePatterns: [
        {
          value: null,
          label: this.$t("uml.form.none"),
        },
        {
          value: "ipPattern",
          label: this.$t("uml.form.ipPattern"),
        },
        {
          value: "urlPattern",
          label: this.$t("uml.form.urlPattern"),
        },
        {
          value: "emailPattern",
          label: this.$t("uml.form.emailPattern"),
        },
        {
          value: "customPattern",
          label: this.$t("uml.form.customPattern.label"),
        },
      ],
      possibleSuperclasses: [],
      form: {},
    };
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    model: {
      validator: (prop) => typeof prop === "object" || prop === null,
      required: true,
    },
  },
  computed: {
    formIsValid() {
      if (!this.validForm) return false;
      if (this.form.properties.find((p) => p.name.trim() === "")) return false;
      return !this.form.properties.find(
        (p) =>
          p.min &&
          p.max &&
          (parseInt(p.min) > parseInt(p.max) ||
            parseInt(p.min) < 0 ||
            parseInt(p.max) < 1)
      );
    },
    possibleClasses() {
      if (!this.dialog) return [];

      const classes = [
        "Boolean",
        "Integer",
        "Long",
        "Float",
        "Double",
        "String",
        "Text",
        "Date",
        "DateTime",
        "Point",
        "MultiPoint",
        "LineString",
        "MultiLineString",
        "Polygon",
        "MultiPolygon",
        "Geometry",
      ];

      if (engineStore.getSpec().features.includes("DM_DS_Address")) {
        classes.push("GCAddress");
      }
      if (engineStore.getSpec().features.includes("DM_DS_Gallery")) {
        classes.push("IGGallery");
      }

      classes.push(...graphHelper.getEnums().map((c) => c.attributes.name));

      return classes;
    },
  },
  watch: {
    model() {
      if (!this.model) return;

      this.form = {
        id: this.model.getId(),
        name: this.model.getName(),
        superclass: this.model.getSuperclass(),
        abstract: this.model.getAbstract(),
        properties: cloneDeep(this.model.getProperties()),
      };

      let pkIndex = this.form.properties.findIndex((p) => p.pk);
      if (pkIndex !== -1) {
        this.form.properties[pkIndex].required = true;
        this.form.properties[pkIndex].unique = true;
      }
      this.possibleSuperclasses = graphHelper.getPossibleSuperclasses(
        this.model.getName()
      );

      this.$nextTick(() => this.$refs.entityForm.validate());
    },
  },
  methods: {
    usesRestrictedKeywords,
    _isValid() {
      return this._validateEntity() && this._validateSuperclass();
    },
    handleRequirements(index) {
      if (this.selectedRow === index) {
        this.selectedRow = null;
      } else {
        this.selectedRow = index;
      }
    },
    handleLength(property) {
      if (!property.lengthConstraint) {
        property.min = null;
        property.max = null;
      }
    },
    handleValue(property) {
      if (!property.valueConstraint) {
        property.min = null;
        property.max = null;
      }
    },
    hasConstraints(property) {
      return (
        property.unique ||
        !isNaN(parseInt(property.max)) ||
        !isNaN(parseInt(property.min)) ||
        property.patternType
      );
    },
    checkLength(e, property, index) {
      let minValue = parseInt(property.min);
      let maxValue = parseInt(property.max);
      if (!isNaN(minValue) && !isNaN(maxValue)) {
        if (minValue > maxValue) {
          this.$refs["min" + index][0].setCustomValidity(
            this.$t("uml.form.validation.min_less_than_max")
          );
          this.$refs["max" + index][0].setCustomValidity(
            this.$t("uml.form.validation.max_greater_than_min")
          );
          this.$refs[e.target.id][0].reportValidity();
        } else if (minValue < 0 || maxValue < 1) {
          if (minValue < 0)
            this.$refs["min" + index][0].setCustomValidity(
              this.$t("uml.form.validation.min")
            );
          if (maxValue < 1)
            this.$refs["max" + index][0].setCustomValidity(
              this.$t("uml.form.validation.max")
            );

          this.$refs[e.target.id][0].reportValidity();
        } else {
          this.$refs["min" + index][0].setCustomValidity("");
          this.$refs["max" + index][0].setCustomValidity("");
        }
      } else {
        if (isNaN(minValue)) this.$refs["min" + index][0].setCustomValidity("");
        if (isNaN(maxValue)) this.$refs["max" + index][0].setCustomValidity("");
      }
    },
    checkValue(e, property, index) {
      let minValue = parseInt(property.min);
      let maxValue = parseInt(property.max);
      if (!isNaN(minValue) && !isNaN(maxValue)) {
        if (minValue > maxValue) {
          this.$refs["minValue" + index][0].setCustomValidity(
            this.$t("uml.form.validation.min_less_than_max")
          );
          this.$refs["maxValue" + index][0].setCustomValidity(
            this.$t("uml.form.validation.max_greater_than_min")
          );
          this.$refs[e.target.id][0].reportValidity();
        } else if (minValue < 0 || maxValue < 1) {
          if (minValue < 0)
            this.$refs["minValue" + index][0].setCustomValidity(
              this.$t("uml.form.validation.min")
            );
          if (maxValue < 1)
            this.$refs["maxValue" + index][0].setCustomValidity(
              this.$t("uml.form.validation.max")
            );

          this.$refs[e.target.id][0].reportValidity();
        } else {
          this.$refs["minValue" + index][0].setCustomValidity("");
          this.$refs["maxValue" + index][0].setCustomValidity("");
        }
      } else {
        if (isNaN(minValue))
          this.$refs["minValue" + index][0].setCustomValidity("");
        if (isNaN(maxValue))
          this.$refs["maxValue" + index][0].setCustomValidity("");
      }
    },
    save(newLink) {
      if (!this._isValid()) return;
      this.model.setName(this.form.name);
      this.model.setProperties(this.form.properties);
      this.model.setAbstract(this.form.abstract);
      if (this.model.getSuperclass()?.name !== this.form.superclass?.name) {
        if (this.model.getSuperclass()?.connectionCellId) {
          graphHelper.removeSuperclassLink(
            this.model.getSuperclass().connectionCellId
          );
        }
        if (this.form.superclass) {
          const targetId = graphHelper
            .getEntities()
            .find((e) => e.getName() === this.form.superclass.name);
          this.form.superclass.connectionCellId = graphHelper.addSuperclassLink(
            {
              source: { id: this.model.getId() },
              target: { id: targetId.getId() },
            }
          ).id;
        }
        this.model.setSuperclass(this.form.superclass);
      }
      if (newLink) {
        this.$emit("newLink", this.model.getId());
      }
      graphHelper.refreshGraphCells();
      this.close();
    },
    close() {
      if (this.model.getName() === "Class") this.removeEntity();
      else this.$emit("close");
    },
    removeEntity() {
      // get all the entities from the diagram that inherit from other entity
      const entitiesWithSuperclass = graphHelper.getEntitiesWithSuperclass(
        this.model.getName()
      );

      // remove the connections and the attribute superclass from those entities and add id property
      entitiesWithSuperclass.forEach((entity) => {
        graphHelper.removeSuperclassLink(
          entity.getSuperclass().connectionCellId
        );
        const properties = entity.getProperties();
        const newProperties = this.updateIdProperty(properties);

        entity.setProperties(newProperties);
        entity.setSuperclass(null);
      });

      // remove the entity from the graph
      this.model.remove();
      this.$emit("close");
      this.deleteEntityDialog = false;
      graphHelper.refreshGraphCells();
    },
    addProperty() {
      this.form.properties.push({ name: "", class: "String" });
    },
    showRemoveProperty(property) {
      this.selectedProperty = property;
      this.deletePropertyDialog = true;
    },
    removeProperty(property) {
      this.form.properties.splice(this.form.properties.indexOf(property), 1);
      this.selectedProperty = null;
      this.deletePropertyDialog = false;
    },
    possibleDisplayString(property) {
      return ["Long", "Integer", "String"].indexOf(property.class) !== -1;
    },
    classChange(property) {
      if (!this.possibleDisplayString(property)) {
        const pk = this.form.properties.find((p) => p.pk);
        if (pk) this.setDisplayString(pk);
      }
      if (!this.isText(property.class)) {
        property.patternType = null;
        property.pattern = null;
      }
      if (!this.isText(property.class) && !this.isNumber(property.class)) {
        property.min = null;
        property.max = null;
      }
    },
    isNumber(clazz) {
      return (
        clazz === "Integer" ||
        clazz === "Long" ||
        clazz === "Float" ||
        clazz === "Double"
      );
    },
    isText(clazz) {
      return clazz === "String" || clazz === "Text";
    },
    setDisplayString(property) {
      this.form.properties
        .filter((p) => p.displayString)
        .forEach((p) => {
          p.displayString = false;
          return true;
        });

      // dejamos primero que termine la acción por defecto del checkout
      // por si lo quiso desmarcar, y luego lo marcamos
      this.$nextTick(() => (property.displayString = true));
    },
    removeChildProperty() {
      graphHelper.removeInheritedDuplicatedProps(this.form);
      this.$notify({
        type: "success",
        text: this.$t("uml.notification.remove_child_properties"),
      });
      this.save(false);
      this.deleteChildPropertyDialog = false;
    },
    checkInheritedId() {
      if (this.form.superclass) {
        this.form.properties.forEach((prop) => {
          if (prop.pk) {
            this.removeProperty(prop);
          }
        });
      } else {
        this.form.properties = this.updateIdProperty(this.form.properties);
      }
    },
    updateIdProperty(properties) {
      const newProperties = [{ name: "id", class: "Long", pk: true }].concat(
        properties
      );

      if (!properties.find((p) => p.displayString))
        newProperties.find((p) => p.pk).displayString = "true";

      return newProperties;
    },
    _validateSuperclass() {
      if (this.form.superclass) {
        if (
          graphHelper.checkSuperclassProps(this.form, this.form.superclass.name)
        ) {
          this.$notify({
            type: "error",
            text: this.$t("uml.notification.superclass_properties"),
          });
          return false;
        }
      }
      if (graphHelper.checkInheritedClassesProps(this.form, null)) {
        this.deleteChildPropertyDialog = true;
        return false;
      }
      return true;
    },
    _validateEntity() {
      if (graphHelper.nameIsUsed(this.form)) {
        this.$notify({
          type: "error",
          text: this.$t("uml.notification.name"),
        });
        return false;
      }
      for (const x of this.form.properties) {
        for (const y of this.form.properties) {
          if (x !== y && x.name === y.name) {
            this.$notify({
              type: "error",
              text: this.$t("uml.notification.duplicated_properties"),
            });
            return false;
          }
        }
      }
      return true;
    },
  },
};
</script>

<style scoped>
input {
  border-bottom: 1px solid rgb(169, 169, 169);
}

input:disabled {
  border-bottom: none;
}

input[type="checkbox"][readonly] {
  pointer-events: none;
}

.icon-align {
  margin-bottom: 5px;
}

.accordion input {
  width: auto;
  margin-right: 4px;
}

.accordion input:invalid {
  border-color: red;
}

.accordion select {
  width: auto;
  margin-left: 4px;
}

select {
  width: 100%;
}

select:enabled {
  -webkit-writing-mode: horizontal-tb !important;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark-color(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-appearance: menulist;
  box-sizing: border-box;
  align-items: center;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  background-color: -internal-light-dark-color(white, black);
  cursor: default;
  margin: 0em;
  font: 400 13.3333px Arial;
  border-radius: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(169, 169, 169);
  border-image: initial;
}
</style>
