var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.dialog, scrollable: "" },
      on: { "click:outside": _vm.close },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-row",
                {
                  staticClass: "white--text",
                  attrs: { align: "center", "no-gutters": "" },
                },
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("custom.list.preview").toUpperCase()) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { align: "center", justify: "space-between" } },
                [
                  _c("v-col", { attrs: { cols: "12", md: "4" } }, [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-right",
                      attrs: { cols: "12", md: "8" },
                    },
                    [
                      _vm.searching
                        ? _c("v-text-field", {
                            staticClass: "d-inline-block",
                            attrs: {
                              dense: "",
                              "append-icon": "search",
                              label: _vm.$t("search"),
                              "single-line": "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          })
                        : _vm._e(),
                      _vm.exportable
                        ? _c("v-btn", { attrs: { color: "primary ml-2" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("export")) + " "),
                          ])
                        : _vm._e(),
                      _vm.form && _vm.form.creatable
                        ? _c("v-btn", { attrs: { color: "success ml-2" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("new")) + " "),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  search: _vm.search,
                },
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.properties, function (prop) {
                      return {
                        key: _vm.slotName(prop),
                        fn: function ({ item }) {
                          return [
                            prop.form
                              ? _c("a", { key: prop.property }, [
                                  _c("u", [
                                    _vm._v(
                                      " " + _vm._s(item[prop.property]) + " "
                                    ),
                                  ]),
                                ])
                              : _c("span", { key: prop.property }, [
                                  _vm._v(
                                    " " + _vm._s(item[prop.property]) + " "
                                  ),
                                ]),
                          ]
                        },
                      }
                    }),
                    {
                      key: `item.action`,
                      fn: function () {
                        return [
                          _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v(" visibility "),
                          ]),
                          _vm.form && _vm.form.editable
                            ? _c("v-icon", { attrs: { color: "warning" } }, [
                                _vm._v(" edit "),
                              ])
                            : _vm._e(),
                          _vm.removeLink
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { color: "error" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.showDeleteDialog()
                                    },
                                  },
                                },
                                [_vm._v(" delete ")]
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                {
                  attrs: {
                    align: "center",
                    justify: "space-between",
                    "no-gutters": "",
                  },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { text: "" },
                          on: { click: _vm.close },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("exit")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-dialog", {
        attrs: {
          dialog: _vm.deleteDialog,
          titleClass: "error white--text",
          titleIcon: "warning",
          submitClass: "error",
          submitText: _vm.$t("delete"),
          title: _vm.$t("delete_title"),
          content: _vm.$t("delete_content"),
        },
        on: {
          cancel: function ($event) {
            _vm.deleteDialog = false
          },
          submit: function ($event) {
            _vm.deleteDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }