<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="6">
        <div id="stencil"></div>
      </v-col>
      <v-col cols="6" class="text-right" align-self="end">
        <v-btn class="mr-2" @click="load()">
          <v-icon>refresh</v-icon>
          <label class="d-none d-md-flex">{{ $t("uml.button.reload") }}</label>
        </v-btn>
        <v-btn :class="{ quadrat: changes }" @click="save()">
          <v-icon>save</v-icon>
          <label class="d-none d-md-flex">{{ $t("uml.button.save") }}</label>
        </v-btn>
        <v-btn icon @click="decreaseHeight()">
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
        <v-btn icon @click="increaseHeight()">
          <v-icon>mdi-arrow-down</v-icon>
        </v-btn>
        <v-btn icon @click="zoomIn()">
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn icon @click="zoomOut()">
          <v-icon>remove</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div ref="paperCanvas" id="paper-container">
          <div id="paper"></div>
        </div>
      </v-col>
    </v-row>

    <modal-entity-form
      @close="entityForm = false"
      @newLink="newLink"
      :dialog="entityForm"
      :model="selectedEntityModel"
    ></modal-entity-form>

    <modal-enum-form
      @close="enumForm = false"
      :dialog="enumForm"
      :model="selectedEnumModel"
    ></modal-enum-form>

    <modal-relationship-form
      @close="relationshipForm = false"
      :dialog="relationshipForm"
      :model="selectedRelationshipModel"
      :source-id="selectedSource"
    ></modal-relationship-form>

    <modal-relationship-delete-dialog
      v-if="removeAssociationModal"
      @close="removeAssociationModal = false"
      :dialog="removeAssociationModal"
      :model="selectedRelationshipModel"
    ></modal-relationship-delete-dialog>
  </v-container>
</template>

<script>
import graphHelper from "./graph.helper";
import engineStore from "@/services/engineStore";
import ModalEntityForm from "./form/ModalEntityForm";
import ModalEnumForm from "./form/ModalEnumForm";
import ModalRelationshipForm from "./form/ModalRelationshipForm";
import ModalRelationshipDeleteDialog from "./form/ModalRelationshipDeleteDialog";

export default {
  name: "DataModel",
  components: {
    ModalRelationshipForm,
    ModalEntityForm,
    ModalEnumForm,
    ModalRelationshipDeleteDialog,
  },
  data() {
    return {
      changes: false,
      entityForm: false,
      selectedEntityModel: null,
      enumForm: false,
      selectedEnumModel: null,
      selectedSource: null,
      selectedRelationshipModel: null,
      relationshipForm: false,
      removeAssociationModal: false,
    };
  },
  mounted() {
    graphHelper.createGraph(
      "paper",
      "stencil",
      this.editCell,
      this.removeAssociation
    );

    this.load();

    window.addEventListener("resize", this.resize);
    this.resize();

    setInterval(() => {
      this.changes =
        this.changes ||
        JSON.stringify(engineStore.dataModel) !==
          JSON.stringify(graphHelper.graphToJSON());
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    _resetModels() {
      this.selectedEntityModel =
        this.selectedEnumModel =
        this.selectedSource =
        this.selectedRelationshipModel =
          null;
    },
    editCell(model) {
      this._resetModels();
      switch (model.attributes.type) {
        case "custom.Class":
          this.entityForm = true;
          this.selectedEntityModel = model;
          break;
        case "custom.Enum":
          this.enumForm = true;
          this.selectedEnumModel = model;
          break;
        case "custom.Association":
          this.selectedRelationshipModel = model;
          this.relationshipForm = true;
      }
    },
    removeAssociation(model) {
      this._resetModels();
      this.removeAssociationModal = true;
      this.selectedRelationshipModel = model;
    },
    resize() {
      graphHelper.resize(
        this.$refs.paperCanvas.offsetWidth,
        this.$refs.paperCanvas.offsetHeight
      );
    },
    decreaseHeight() {
      graphHelper.increaseHeight(-200);
    },
    increaseHeight() {
      graphHelper.increaseHeight(200);
    },
    zoomIn() {
      graphHelper.zoom(0.2);
    },
    zoomOut() {
      graphHelper.zoom(-0.2);
    },
    newLink(value) {
      this._resetModels();
      this.selectedSource = value;
      this.relationshipForm = true;
    },
    load() {
      if (engineStore.dataModel.cells) {
        graphHelper.graphFromJSON(engineStore.dataModel);
      }
      this.changes = false;
    },
    save() {
      engineStore.dataModel = graphHelper.graphToJSON();
      this.changes = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.quadrat {
  -webkit-animation: save-badge-animation 1s infinite; /* Safari 4+ */
  -moz-animation: save-badge-animation 1s infinite; /* Fx 5+ */
  -o-animation: save-badge-animation 1s infinite; /* Opera 12+ */
  animation: save-badge-animation 1s infinite; /* IE 10+, Fx 29+ */
}
.container {
  width: 100% !important;
}
#paper-container {
  border-top: LightGray 1px ridge;
  position: absolute;
  left: 0;
  right: 0;
  top: 99px;
  bottom: 0;
  overflow: scroll;
}
@keyframes save-badge-animation {
  0%,
  49% {
    background-color: #fb8c00;
    border-color: #fb8c00;
    color: #ffffff;
  }
  50%,
  100% {
    color: #333;
    background-color: #f5f5f5;
  }
}
</style>
