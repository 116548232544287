import { render, staticRenderFns } from "./DetailPreview.vue?vue&type=template&id=648e9eca"
import script from "./DetailPreview.vue?vue&type=script&lang=js"
export * from "./DetailPreview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('648e9eca')) {
      api.createRecord('648e9eca', component.options)
    } else {
      api.reload('648e9eca', component.options)
    }
    module.hot.accept("./DetailPreview.vue?vue&type=template&id=648e9eca", function () {
      api.rerender('648e9eca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/client/components/customization/form_customization/DetailPreview.vue"
export default component.exports