<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="2">
        <v-text-field
          v-model="user"
          autocomplete="username"
          autofocus
          :label="$t('login.username')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="2">
        <v-text-field
          v-model="password"
          autocomplete="password"
          :label="$t('login.password')"
          type="password"
          @keypress.enter="logIn"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" class="mt-4">
      <v-btn color="primary" @click="logIn">{{ $t("login.login") }}</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import auth from "@/services/auth";
import store from "@/services/store";

export default {
  name: "Login",
  data() {
    return {
      user: "",
      password: "",
    };
  },
  created() {
    store.navigation.pageTitle = this.$t("login.title");
  },
  methods: {
    logIn: function () {
      auth
        .login({ username: this.user, password: this.password })
        .then(() => {
          if (!store.navigation.thereIsPageBeforeLogin) {
            store.navigation.thereIsPageBeforeLogin = true;
            this.$router.push("/");
          } else {
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.$notify({
            type: "error",
            text: "Invalid username or password. Please, try again.",
          });
          console.error(err);
        });
    },
  },
};
</script>

<style scoped></style>
