<template>
  <v-dialog v-if="dialog" :value="dialog" @click:outside="close" scrollable>
    <v-card>
      <v-tabs grow v-model="tab" background-color="primary">
        <v-tab>
          {{ $t("detail") }}
        </v-tab>
        <v-tab v-if="creatable || editable">
          {{ $t(options) }}
        </v-tab>
      </v-tabs>
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <detail-preview
              :properties="visibleProperties"
              :visibility="visibility"
              :name="name"
              :removable="removable"
              :editable="editable"
              v-if="tab == 0"
            >
            </detail-preview>
          </v-tab-item>
          <v-tab-item>
            <form-preview
              :properties="editableProperties"
              :editability="visibility"
              :name="name"
              v-if="tab == 1"
            >
            </form-preview>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="space-between" no-gutters>
          <v-col class="text-right">
            <v-btn text @click="close" class="white--text">
              {{ $t("exit") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormPreview from "./FormPreview";
import DetailPreview from "./DetailPreview";
export default {
  props: [
    "visibleProperties",
    "visibility",
    "name",
    "dialog",
    "editableProperties",
    "removable",
    "editable",
    "creatable",
  ],
  data() {
    return {
      tab: null,
    };
  },
  components: {
    FormPreview,
    DetailPreview,
  },
  computed: {
    options() {
      if (this.creatable && this.editable) return "creation/edition";
      else if (this.creatable) return "creation";
      else if (this.editable) return "edition";
      else return "";
    },
  },
  methods: {
    close() {
      this.$emit("exit");
    },
  },
};
</script>

<style scoped>
.v-card__actions {
  background-color: #1976d2;
}
</style>
