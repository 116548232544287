<template>
  <v-app-bar app dark color="primary">
    <router-link to="/">
      <v-toolbar-title class="white--text mr-4">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on"> GEMA </span>
          </template>
          {{ $t("menu.banner_tooltip") }}
        </v-tooltip>
      </v-toolbar-title>
    </router-link>

    <v-toolbar-title>
      <slot name="title"></slot>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-toolbar-items>
      <slot name="items"></slot>

      <v-menu open-on-hover offset-y v-if="!userIsLogged">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            {{ $t("language") }}
            <v-icon>arrow_drop_down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="item in languages"
            :key="item.language"
            @click="changeLocale(item.language)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu open-on-click offset-y v-else>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-group @click.stop no-action prepend-icon="language">
            <template v-slot:activator>
              <v-list-item-title>{{ $t("language") }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="item in languages"
              :key="item.language"
              @click="changeLocale(item.language)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>power_settings_new</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              $t("menu.logout_tooltip")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import auth from "@/services/auth";
import i18n from "@/plugins/i18n";
import engineStore from "@/services/engineStore";
import store from "@/services/store";
import customization from "@/services/customization.service";

export default {
  data() {
    return {
      languages: [
        { flag: "uk", language: "en", title: "English" },
        { flag: "es", language: "es", title: "Español" },
      ],
    };
  },
  computed: {
    userIsLogged() {
      return auth.userIsLogged();
    },
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
    },
    logout: function () {
      auth.logout();
      engineStore.resetSpec();
      customization.endCustomization();
      store.navigation.thereIsPageBeforeLogin = false;
      this.$router.push("/login");
    },
  },
};
</script>
