import FormCustomization from "./FormCustomization_List.vue";
import FormCustomization_Form from "./FormCustomization_Form.vue";

const routes = [
  {
    name: "FormCustomization_List",
    path: "customization/forms",
    component: FormCustomization,
  },
  {
    name: "FormCustomization_Form",
    path: "customization/forms/:id",
    component: FormCustomization_Form,
  },
  {
    name: "FormCustomization_Create",
    path: "customization/forms/new",
    component: FormCustomization_Form,
  },
];

export default routes;
