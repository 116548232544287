<template>
  <v-card>
    <v-card-title>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col>
          <span class="headline">
            {{ name }}
          </span>
        </v-col>
        <v-col class="text-right">
          <v-btn>
            {{ $t("back") }}
          </v-btn>
          <v-btn v-if="editable" class="success ml-2">
            {{ $t("edit") }}
          </v-btn>
          <v-btn
            v-if="removable"
            class="error ml-2"
            @click="deleteDialog = true"
          >
            {{ $t("remove") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col
          cols="12"
          :md="textFieldsMdCols"
          class="d-flex"
          style="flex-direction: column"
        >
          <v-row
            v-for="property in textFieldProperties"
            :key="property.name"
            align="center"
            dense
          >
            <v-col cols="3" md="2" class="text-right font-weight-bold">
              {{ property.name }}:
            </v-col>
            <v-col cols="9" md="10" v-if="isEntity(property)">
              <router-link :to="$route.path">
                A {{ property.name }}
              </router-link>
            </v-col>
            <v-col cols="9" md="10" v-else-if="property.class == 'Date'">
              {{ currentDate() }}
            </v-col>
            <v-col cols="9" md="10" v-else-if="property.class == 'DateTime'">
              {{ currentDateTime() }}
            </v-col>
            <v-col cols="9" md="10" v-else-if="isEnum(property)">
              {{ randomEnumValue(property) }}
            </v-col>
            <v-col cols="9" md="10" v-else> A {{ property.name }} </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex"
          style="flex-direction: column"
          v-for="property in columnProperties"
          :key="property.name"
        >
          <v-row align="center" dense>
            <v-col cols="3" md="2" class="text-right font-weight-bold">
              {{ property.name }}:
            </v-col>
            <v-col cols="9" md="10" v-if="isGeographic(property)">
              <map-field
                :id="property.name"
                height="400px"
                :editable="false"
                :entity="{ sampleGeom: null }"
                propName="sampleGeom"
              ></map-field>
            </v-col>
            <v-col cols="9" md="10" v-else-if="property.class == 'IGGallery'">
              <v-card>
                <gallery></gallery>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="d-flex"
          style="flex-direction: column"
          v-for="property in rowProperties"
          :key="property.name"
        >
          <v-row align="center" dense>
            <v-col cols="3" md="1" class="text-right font-weight-bold">
              {{ property.name }}:
            </v-col>
            <v-col cols="9" md="11">
              <gc-address-detail :id="property.name"></gc-address-detail>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <modal-dialog
      @cancel="deleteDialog = false"
      @submit="deleteDialog = false"
      :dialog="deleteDialog"
      titleClass="error white--text"
      titleIcon="warning"
      submitClass="error"
      :submitText="$t('delete')"
      :title="$t('delete_title')"
      :content="$t('delete_content')"
    ></modal-dialog>
  </v-card>
</template>

<script>
import customization from "@/services/customization.service";
import MapField from "@/components/common/map-field/MapField.vue";
import GCAddressDetail from "@/components/common/gc-address/GCAddressDetail";
import Gallery from "@/components/common/gallery/Gallery";
import ModalDialog from "@/components/common/ModalDialog";
const columnTypes = [
  "IGGallery",
  "Geometry",
  "Polygon",
  "LineString",
  "Point",
  "MultiPolygon",
  "MultiLineString",
  "MultiPoint",
];
const rowTypes = ["GCAddress"];
export default {
  props: ["properties", "visibility", "removable", "editable", "name"],
  data() {
    return {
      customization,
      entities: [],
      enums: [],
      deleteDialog: false,
    };
  },
  components: {
    MapField,
    ModalDialog,
    Gallery,
    "gc-address-detail": GCAddressDetail,
  },
  computed: {
    textFieldProperties() {
      // Everything that isn't a column or a row property
      return this.properties.filter(
        (prop) =>
          this.isVisible(prop) &&
          !columnTypes.includes(prop.class) &&
          !rowTypes.includes(prop.class)
      );
    },
    columnProperties() {
      return this.properties.filter(
        (prop) => this.isVisible(prop) && columnTypes.includes(prop.class)
      );
    },
    rowProperties() {
      return this.properties.filter(
        (prop) => this.isVisible(prop) && rowTypes.includes(prop.class)
      );
    },
    textFieldsMdCols() {
      return this.columnProperties.length >= 1 ? 6 : 12;
    },
  },
  created() {
    this.entities = this.customization.data.data.dataModel.entities;
    this.enums = this.customization.data.data.dataModel.enums;
  },
  methods: {
    isEntity(property) {
      return (
        this.entities.find((el) => el.name == property.class) && property.owner
      );
    },
    isEnum(property) {
      return this.enums.find((el) => el.name == property.class);
    },
    isGeographic(property) {
      return (
        property.class == "Point" ||
        property.class == "LineString" ||
        property.class == "Polygon" ||
        property.class == "MultiPoint" ||
        property.class == "MultiLineString" ||
        property.class == "MultiPolygon"
      );
    },
    isVisible(property) {
      return this.visibility.find((el) => el.property == property.name).viewing;
    },
    currentDate() {
      let date = new Date();
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        date.getFullYear()
      );
    },
    currentDateTime() {
      let date = new Date();
      return (
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        date.getFullYear() +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2)
      );
    },
    randomEnumValue(property) {
      const index = this.enums.findIndex((el) => el.name == property.class);
      let enumerated = this.enums[index];
      return enumerated.values[
        Math.floor(Math.random() * enumerated.values.length)
      ];
    },
  },
};
</script>

<style></style>
