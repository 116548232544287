var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("nav-bar", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "v-container",
                  { staticClass: "pl-0", attrs: { fluid: "" } },
                  [
                    _c("span", { staticClass: "font-italic" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.engineStore.basicData.name) +
                          " (v. " +
                          _vm._s(_vm.engineStore.basicData.version) +
                          ") "
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "items",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { to: { name: "ProjectMain" }, text: "", exact: "" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$tc("project.name", 1)) + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { to: { name: "FeatureTree" }, text: "", exact: "" },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("menu.feature_selection")) + " ")]
                ),
                _c(
                  "v-btn",
                  { attrs: { to: { name: "DataModel" }, text: "", exact: "" } },
                  [_vm._v(" " + _vm._s(_vm.$t("menu.class_diagram")) + " ")]
                ),
                _vm.staticsSelected
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          to: { name: "Static_PageList" },
                          text: "",
                          exact: "",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("menu.statics")) + " ")]
                    )
                  : _vm._e(),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  class: { quadrat: _vm.changes },
                                  attrs: { icon: "" },
                                  on: { click: _vm.save },
                                },
                                on
                              ),
                              [_c("v-icon", [_vm._v("save")])],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("menu.save_tooltip")) + " ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }