var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "space-between" } },
            [
              _c("v-col", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v(_vm._s(_vm.name)),
                ]),
              ]),
              _c(
                "v-col",
                { staticClass: "text-right" },
                [
                  _c("v-btn", [_vm._v(_vm._s(_vm.$t("cancel")))]),
                  _c("v-btn", { staticClass: "success ml-2" }, [
                    _vm._v(_vm._s(_vm.$t("save"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-form",
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: _vm.textFieldsMdCols } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        _vm._l(_vm.textFieldProperties, function (property) {
                          return _c(
                            "v-col",
                            {
                              key: property.name,
                              staticClass: "field-container",
                              attrs: {
                                cols: "12",
                                md: _vm.singleTextFieldMdCols,
                              },
                            },
                            [
                              property.class.indexOf("autoinc") != -1
                                ? _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      type: "number",
                                      disabled: true,
                                      label: property.name,
                                    },
                                  })
                                : _vm.isNumber(property)
                                ? _c("number-field", {
                                    attrs: {
                                      dense: "",
                                      type: property.class,
                                      label: property.name,
                                    },
                                  })
                                : property.class == "Boolean"
                                ? _c("v-switch", {
                                    staticClass: "ma-2 tp-0",
                                    attrs: { dense: "", label: property.name },
                                  })
                                : property.class == "Date"
                                ? _c("date-and-hour-picker", {
                                    attrs: {
                                      datePickerProp: {
                                        data: null,
                                        label: property.name,
                                      },
                                    },
                                  })
                                : property.class == "DateTime"
                                ? _c("date-and-hour-picker", {
                                    attrs: {
                                      datePickerProp: {
                                        data: null,
                                        label: property.name,
                                      },
                                      timePickerProp: {
                                        data: null,
                                        label: property.name,
                                      },
                                    },
                                  })
                                : property.class == "String"
                                ? _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      type: "text",
                                      label: property.name,
                                    },
                                  })
                                : _vm._e(),
                              _vm.isEnum(property)
                                ? _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      label: property.name,
                                      items: _vm.enumValues(property),
                                    },
                                  })
                                : _vm.isEntity(property)
                                ? _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      label: property.name,
                                      multiple: property.multiple,
                                      "return-object": "",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.columnProperties, function (property) {
                    return _c(
                      "v-col",
                      {
                        key: property.name,
                        staticClass: "field-container",
                        attrs: { cols: "12", md: "6" },
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "font-weight-bold",
                            attrs: { cols: "12" },
                          },
                          [_vm._v(" " + _vm._s(property.name) + ": ")]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _vm.isGeographic(property)
                              ? _c("map-field", {
                                  attrs: {
                                    id: property.name,
                                    height: "400px",
                                    entity: { sampleGeom: null },
                                    geomType: property.class,
                                    editable: true,
                                    propName: "sampleGeom",
                                  },
                                })
                              : property.class == "IGGallery"
                              ? _c(
                                  "v-card",
                                  [_c("gallery", { attrs: { isEdit: true } })],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._l(_vm.rowProperties, function (property) {
                    return _c(
                      "v-col",
                      {
                        key: property.name,
                        staticClass: "field-container",
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "font-weight-bold",
                            attrs: { cols: "12" },
                          },
                          [_vm._v(" " + _vm._s(property.name) + ": ")]
                        ),
                        property.class == "GCAddress"
                          ? _c("gc-address-form", {
                              attrs: { id: property.name },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }