var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", {
    ref: _vm.id,
    staticClass: "ml-md-1 m-0 p-00",
    attrs: { id: _vm.id },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }