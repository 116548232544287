import DataModel from "./DataModel";

const routes = [
  {
    name: "DataModel",
    path: "datamodel",
    component: DataModel,
  },
];

export default routes;
