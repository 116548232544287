var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "2" } },
            [
              _c("v-select", {
                staticClass: "selector",
                attrs: {
                  items: _vm.projects,
                  placeholder: _vm.$t("project.placeholder.name"),
                  loading: _vm.projectsLoading,
                },
                on: { change: _vm.getVersions },
                model: {
                  value: _vm.selected.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.selected, "name", $$v)
                  },
                  expression: "selected.name",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "2" } },
            [
              _c("v-select", {
                staticClass: "selector",
                attrs: {
                  disabled: _vm.versions.length === 0,
                  items: _vm.versions,
                  placeholder: _vm.$t("project.placeholder.version"),
                  loading: _vm.versionsLoading,
                },
                model: {
                  value: _vm.selected.version,
                  callback: function ($$v) {
                    _vm.$set(_vm.selected, "version", $$v)
                  },
                  expression: "selected.version",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "1" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: !_vm.selected.version,
                    outlined: "",
                    color: "primary",
                  },
                  on: { click: _vm.loadProject },
                },
                [_vm._v(" " + _vm._s(_vm.$t("load")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { md: "3" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { block: "", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.newBlankProject()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("project.button.empty")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { block: "", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.newProjectFromImport()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("project.button.fromSpec")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("input", {
        ref: "hiddenUploader",
        staticClass: "d-none",
        attrs: { type: "file" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }