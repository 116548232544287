import { shapes, dia } from "jointjs";
import defaultsDeep from "lodash.defaultsdeep";
import markup from "./markup.svg";

shapes.custom = shapes.custom || {};

shapes.custom.Class = shapes.basic.Generic.extend({
  defaults: defaultsDeep(
    {
      type: "custom.Class",
      markup: markup,

      attrs: {
        rect: { width: 200 },

        ".uml-class-name-rect": {
          stroke: "black",
          "stroke-width": 2,
          fill: "#ddd",
        },
        ".uml-class-properties-rect": {
          stroke: "black",
          "stroke-width": 2,
          fill: "#fff",
        },

        ".uml-class-name-text": {
          ref: ".uml-class-name-rect",
          "ref-y": 0.5,
          "ref-x": 0.5,
          "text-anchor": "middle",
          "y-alignment": "middle",
          "font-weight": "bold",
          fill: "black",
          "font-size": 12,
          "font-family": "Times New Roman",
        },
        ".uml-class-properties-text": {
          ref: ".uml-class-properties-rect",
          "ref-y": 5,
          "ref-x": 5,
          fill: "black",
          "font-size": 12,
          "font-family": "Times New Roman",
        },
      },
      name: [],
      properties: [],
      superclass: null,
    },
    shapes.basic.Generic.prototype.defaults
  ),

  initialize: function () {
    this.on(
      "change:name change:properties",
      function () {
        this.updateRectangles();
        this.trigger("uml-update");
      },
      this
    );

    this.updateRectangles();
    shapes.basic.Generic.prototype.initialize.apply(this, arguments);
  },

  editable: function () {},

  getName: function () {
    return this.get("name");
  },

  setName: function (newName) {
    this.set("name", newName);
  },

  getAbstract: function () {
    return this.get("abstract");
  },

  setAbstract: function (newAbstract) {
    this.set("abstract", newAbstract);
  },

  getProperties: function () {
    return this.get("properties");
  },

  setProperties: function (newProperties) {
    this.set("properties", newProperties);
  },

  getSuperclass: function () {
    return this.get("superclass");
  },

  setSuperclass: function (superclass) {
    this.set("superclass", superclass);
  },

  getId: function () {
    return this.get("id");
  },

  updateRectangles: function () {
    const attrs = this.get("attrs");
    var rects = [
      {
        type: "name",
        text: this.getAbstract()
          ? ["<<abstract>>", this.getName()]
          : [this.getName()],
      },
      { type: "properties", text: getPropertiesText(this.get("properties")) },
    ];

    let width = this.getName().length * 6 + 5;
    getPropertiesText(this.get("properties")).forEach(function (p) {
      width = Math.max(width, p.length * 6);
    });

    attrs[".uml-class-name-rect"].width = width;
    attrs[".uml-class-properties-rect"].width = width;

    let offsetY = 0;

    rects.forEach(function (rect) {
      const lines = Array.isArray(rect.text) ? rect.text : [rect.text];
      const rectHeight = lines.length * 13 + 10;

      attrs[".uml-class-" + rect.type + "-text"].text = lines.join("\n");
      attrs[".uml-class-" + rect.type + "-rect"].height = rectHeight;
      attrs[".uml-class-" + rect.type + "-rect"].transform =
        "translate(0," + offsetY + ")";

      offsetY += rectHeight;
    });
  },
});

function getPropertiesText(properties) {
  return properties.map(function (a) {
    return a.name + ": " + a.class;
  });
}

shapes.custom.ClassView = dia.ElementView.extend({
  initialize: function () {
    dia.ElementView.prototype.initialize.apply(this, arguments);

    this.listenTo(this.model, "uml-update", function () {
      this.update();
      this.resize();
    });
  },
});
