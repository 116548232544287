var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { "max-width": "800px", persistent: "", value: _vm.dialog } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "headline primary white--text",
              attrs: { "primary-title": "" },
            },
            [_vm._v(" " + _vm._s(_vm.$tc("entity.name", 1)) + " ")]
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "entityForm",
                  model: {
                    value: _vm.validForm,
                    callback: function ($$v) {
                      _vm.validForm = $$v
                    },
                    expression: "validForm",
                  },
                },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t("uml.form.entity_name"),
                                  rules: [
                                    (v) => !!v || _vm.$t("validation.required"),
                                    (v) =>
                                      _vm.usesRestrictedKeywords(v) ||
                                      v +
                                        " " +
                                        _vm.$t(
                                          "uml.form.validation.postgres_keywords"
                                        ),
                                    (v) =>
                                      v !== "Class" ||
                                      _vm.$t("uml.form.validation.class_name"),
                                    (v) =>
                                      !v ||
                                      v.search(" ") === -1 ||
                                      _vm.$t("uml.form.validation.space_name"),
                                  ],
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { align: "center", "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  clearable: "",
                                  label: _vm.$t("uml.form.superclass"),
                                  items: _vm.possibleSuperclasses,
                                  "item-text": "name",
                                  "return-object": "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.checkInheritedId()
                                  },
                                },
                                model: {
                                  value: _vm.form.superclass,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "superclass", $$v)
                                  },
                                  expression: "form.superclass",
                                },
                              }),
                              _c("v-checkbox", {
                                attrs: { label: _vm.$t("uml.form.abstract") },
                                model: {
                                  value: _vm.form.abstract,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "abstract", $$v)
                                  },
                                  expression: "form.abstract",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "8" } }, [
                            _c("span", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.$tc("property.name", 2))),
                            ]),
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "text-right", attrs: { cols: "4" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addProperty()
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("add")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-simple-table", { attrs: { dense: "" } }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("uml.form.property_name")) +
                                  " "
                              ),
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("uml.form.class")) + " "
                              ),
                            ]),
                            _c(
                              "th",
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-center d-inline-flex",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "uml.form.display_string"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    { attrs: { small: "" } },
                                                    on
                                                  ),
                                                  [_vm._v("help")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "uml.form.display_string_tooltip"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("uml.form.required")) + " "
                              ),
                            ]),
                            _c("th", [
                              _vm._v(_vm._s(_vm.$t("uml.form.constraints"))),
                            ]),
                            _c("th"),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm._l(
                              _vm.form.properties,
                              function (property, index) {
                                return [
                                  _c(
                                    "tr",
                                    { key: index, attrs: { align: "center" } },
                                    [
                                      _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              dense: "",
                                              disabled: property.pk,
                                              flat: "",
                                              "hide-details": "auto",
                                              placeholder: _vm.$t(
                                                "uml.form.property_name"
                                              ),
                                              rules: [
                                                (v) =>
                                                  !v ||
                                                  v.search(" ") === -1 ||
                                                  _vm.$t(
                                                    "uml.form.validation.space_name"
                                                  ),
                                                (v) =>
                                                  _vm.usesRestrictedKeywords(
                                                    v
                                                  ) ||
                                                  v +
                                                    " " +
                                                    _vm.$t(
                                                      "uml.form.validation.postgres_keywords"
                                                    ),
                                              ],
                                            },
                                            model: {
                                              value: property.name,
                                              callback: function ($$v) {
                                                _vm.$set(property, "name", $$v)
                                              },
                                              expression: "property.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: property.class,
                                                expression: "property.class",
                                              },
                                            ],
                                            attrs: { disabled: property.pk },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    property,
                                                    "class",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.classChange(
                                                    property
                                                  )
                                                },
                                              ],
                                            },
                                          },
                                          _vm._l(
                                            _vm.possibleClasses,
                                            function (item) {
                                              return _c(
                                                "option",
                                                { key: item },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(item) + " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm.possibleDisplayString(property)
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: property.displayString,
                                                  expression:
                                                    "property.displayString",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  property.displayString
                                                )
                                                  ? _vm._i(
                                                      property.displayString,
                                                      null
                                                    ) > -1
                                                  : property.displayString,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$a =
                                                        property.displayString,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            property,
                                                            "displayString",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            property,
                                                            "displayString",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        property,
                                                        "displayString",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  function ($event) {
                                                    return _vm.setDisplayString(
                                                      property
                                                    )
                                                  },
                                                ],
                                              },
                                            })
                                          : _vm._e(),
                                      ]),
                                      _c("td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: property.required,
                                              expression: "property.required",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            disabled: property.pk,
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              property.required
                                            )
                                              ? _vm._i(
                                                  property.required,
                                                  null
                                                ) > -1
                                              : property.required,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = property.required,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      property,
                                                      "required",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      property,
                                                      "required",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  property,
                                                  "required",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !property.pk,
                                                expression: "!property.pk",
                                              },
                                            ],
                                            staticStyle: {
                                              "margin-top": "3px",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            _vm._g(
                                                              _vm._b(
                                                                {},
                                                                "span",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _c("input", {
                                                                attrs: {
                                                                  type: "checkbox",
                                                                  value: "1",
                                                                  readonly: "",
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    _vm.hasConstraints(
                                                                      property
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.hasConstraints(
                                                            property
                                                          ),
                                                        expression:
                                                          "hasConstraints(property)",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "uml.form.hasConstraints.true"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          !_vm.hasConstraints(
                                                            property
                                                          ),
                                                        expression:
                                                          "!hasConstraints(property)",
                                                      },
                                                    ],
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "uml.form.hasConstraints.false"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.selectedRow !== index,
                                                    expression:
                                                      "selectedRow !== index",
                                                  },
                                                ],
                                                staticClass:
                                                  "pointer icon-align",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleRequirements(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" arrow_drop_down ")]
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.selectedRow === index,
                                                    expression:
                                                      "selectedRow === index",
                                                  },
                                                ],
                                                staticClass:
                                                  "pointer icon-align",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleRequirements(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" arrow_drop_up ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !property.pk,
                                                  expression: "!property.pk",
                                                },
                                              ],
                                              staticClass: "pointer",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showRemoveProperty(
                                                    property
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("delete")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "tr",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.selectedRow === index,
                                          expression: "selectedRow === index",
                                        },
                                      ],
                                      key: "A" + index,
                                      staticClass: "accordion",
                                    },
                                    [
                                      _c(
                                        "td",
                                        { attrs: { colspan: "6" } },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c("span", [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "uml.form.constraints"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: property.unique,
                                                        expression:
                                                          "property.unique",
                                                      },
                                                    ],
                                                    attrs: {
                                                      id: "unique" + index,
                                                      type: "checkbox",
                                                      disabled: property.pk,
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        property.unique
                                                      )
                                                        ? _vm._i(
                                                            property.unique,
                                                            null
                                                          ) > -1
                                                        : property.unique,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            property.unique,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                property,
                                                                "unique",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                property,
                                                                "unique",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            property,
                                                            "unique",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "label",
                                                    {
                                                      attrs: {
                                                        for: "unique" + index,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "uml.form.unique"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              property.class === "String" ||
                                              property.class === "Text"
                                                ? [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "3",
                                                        },
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                property.lengthConstraint,
                                                              expression:
                                                                "property.lengthConstraint",
                                                            },
                                                          ],
                                                          attrs: {
                                                            id:
                                                              "length" + index,
                                                            type: "checkbox",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              Array.isArray(
                                                                property.lengthConstraint
                                                              )
                                                                ? _vm._i(
                                                                    property.lengthConstraint,
                                                                    null
                                                                  ) > -1
                                                                : property.lengthConstraint,
                                                          },
                                                          on: {
                                                            change: [
                                                              function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    property.lengthConstraint,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        property,
                                                                        "lengthConstraint",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        property,
                                                                        "lengthConstraint",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    property,
                                                                    "lengthConstraint",
                                                                    $$c
                                                                  )
                                                                }
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.handleLength(
                                                                  property
                                                                )
                                                              },
                                                            ],
                                                          },
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for:
                                                                "length" +
                                                                index,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "uml.form.length"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                        attrs: {
                                                          cols: "12",
                                                          md: "9",
                                                        },
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                property.min,
                                                              expression:
                                                                "property.min",
                                                            },
                                                          ],
                                                          ref: "min" + index,
                                                          refInFor: true,
                                                          attrs: {
                                                            id: "min" + index,
                                                            type: "number",
                                                            min: "0",
                                                            disabled:
                                                              !property.lengthConstraint,
                                                            placeholder:
                                                              _vm.$t(
                                                                "uml.form.min"
                                                              ),
                                                          },
                                                          domProps: {
                                                            value: property.min,
                                                          },
                                                          on: {
                                                            input: [
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  property,
                                                                  "min",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.checkLength(
                                                                  $event,
                                                                  property,
                                                                  index
                                                                )
                                                              },
                                                            ],
                                                          },
                                                        }),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                property.max,
                                                              expression:
                                                                "property.max",
                                                            },
                                                          ],
                                                          ref: "max" + index,
                                                          refInFor: true,
                                                          attrs: {
                                                            id: "max" + index,
                                                            type: "number",
                                                            min: "1",
                                                            disabled:
                                                              !property.lengthConstraint,
                                                            placeholder:
                                                              _vm.$t(
                                                                "uml.form.max"
                                                              ),
                                                          },
                                                          domProps: {
                                                            value: property.max,
                                                          },
                                                          on: {
                                                            input: [
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  property,
                                                                  "max",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.checkLength(
                                                                  $event,
                                                                  property,
                                                                  index
                                                                )
                                                              },
                                                            ],
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "4" } },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for: "patternType",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "uml.form.pattern"
                                                                  )
                                                                ) +
                                                                " : "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  property.patternType,
                                                                expression:
                                                                  "property.patternType",
                                                              },
                                                            ],
                                                            attrs: {
                                                              id:
                                                                "patternType" +
                                                                index,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  property,
                                                                  "patternType",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.possiblePatterns,
                                                            function (item) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key: item.value,
                                                                  domProps: {
                                                                    value:
                                                                      item.value,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.label
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    ),
                                                    property.patternType ==
                                                    "customPattern"
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                            attrs: {
                                                              cols: "8",
                                                            },
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    property.pattern,
                                                                  expression:
                                                                    "property.pattern",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                required: "",
                                                                type: "text",
                                                                placeholder:
                                                                  _vm.$t(
                                                                    "uml.form.customPattern.placeholder"
                                                                  ),
                                                              },
                                                              domProps: {
                                                                value:
                                                                  property.pattern,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      property,
                                                                      "pattern",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                              _vm.isNumber(property.class) &&
                                              !property.pk
                                                ? [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "3",
                                                        },
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                property.valueConstraint,
                                                              expression:
                                                                "property.valueConstraint",
                                                            },
                                                          ],
                                                          attrs: {
                                                            id: "value" + index,
                                                            type: "checkbox",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              Array.isArray(
                                                                property.valueConstraint
                                                              )
                                                                ? _vm._i(
                                                                    property.valueConstraint,
                                                                    null
                                                                  ) > -1
                                                                : property.valueConstraint,
                                                          },
                                                          on: {
                                                            change: [
                                                              function (
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    property.valueConstraint,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c =
                                                                    $$el.checked
                                                                      ? true
                                                                      : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v =
                                                                      null,
                                                                    $$i =
                                                                      _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        property,
                                                                        "valueConstraint",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        property,
                                                                        "valueConstraint",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    property,
                                                                    "valueConstraint",
                                                                    $$c
                                                                  )
                                                                }
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.handleValue(
                                                                  property
                                                                )
                                                              },
                                                            ],
                                                          },
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            attrs: {
                                                              for:
                                                                "value" + index,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "uml.form.values"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "text-center",
                                                        attrs: {
                                                          cols: "12",
                                                          md: "9",
                                                        },
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                property.min,
                                                              expression:
                                                                "property.min",
                                                            },
                                                          ],
                                                          ref:
                                                            "minValue" + index,
                                                          refInFor: true,
                                                          attrs: {
                                                            id:
                                                              "minValue" +
                                                              index,
                                                            type: "number",
                                                            min: "0",
                                                            disabled:
                                                              !property.valueConstraint,
                                                            placeholder:
                                                              _vm.$t(
                                                                "uml.form.min"
                                                              ),
                                                          },
                                                          domProps: {
                                                            value: property.min,
                                                          },
                                                          on: {
                                                            input: [
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  property,
                                                                  "min",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.checkValue(
                                                                  $event,
                                                                  property,
                                                                  index
                                                                )
                                                              },
                                                            ],
                                                          },
                                                        }),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                property.max,
                                                              expression:
                                                                "property.max",
                                                            },
                                                          ],
                                                          ref:
                                                            "maxValue" + index,
                                                          refInFor: true,
                                                          attrs: {
                                                            id:
                                                              "maxValue" +
                                                              index,
                                                            type: "number",
                                                            min: "1",
                                                            disabled:
                                                              !property.valueConstraint,
                                                            placeholder:
                                                              _vm.$t(
                                                                "uml.form.max"
                                                              ),
                                                          },
                                                          domProps: {
                                                            value: property.max,
                                                          },
                                                          on: {
                                                            input: [
                                                              function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  property,
                                                                  "max",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.checkValue(
                                                                  $event,
                                                                  property,
                                                                  index
                                                                )
                                                              },
                                                            ],
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.formIsValid },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.formIsValid },
                  on: {
                    click: function ($event) {
                      return _vm.save(true)
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("uml.button.save_add_relationship")) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "warning",
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "error" },
                  on: {
                    click: function ($event) {
                      _vm.deleteEntityDialog = true
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-dialog", {
        attrs: {
          dialog: _vm.deleteEntityDialog,
          title: _vm.$t("uml.dialog.entity.delete_title"),
          titleClass: "error white--text",
          titleIcon: "warning",
          submitClass: "error",
          submitText: _vm.$t("delete"),
          content: _vm.$t("uml.dialog.entity.delete_dialog"),
        },
        on: {
          cancel: function ($event) {
            _vm.deleteEntityDialog = false
          },
          submit: function ($event) {
            return _vm.removeEntity()
          },
        },
      }),
      _c("modal-dialog", {
        attrs: {
          dialog: _vm.deletePropertyDialog,
          title: _vm.$t("uml.dialog.property.delete_title"),
          titleClass: "error white--text",
          titleIcon: "warning",
          submitClass: "error",
          submitText: _vm.$t("delete"),
          content: _vm.$t("uml.dialog.property.delete_dialog"),
        },
        on: {
          cancel: function ($event) {
            _vm.deletePropertyDialog = false
          },
          submit: function ($event) {
            return _vm.removeProperty(_vm.selectedProperty)
          },
        },
      }),
      _c("modal-dialog", {
        attrs: {
          dialog: _vm.deleteChildPropertyDialog,
          title: _vm.$t("uml.dialog.property.delete_title"),
          titleClass: "error white--text",
          titleIcon: "warning",
          submitClass: "error",
          submitText: _vm.$t("delete"),
          content: _vm.$t("uml.notification.child_properties"),
        },
        on: {
          cancel: function ($event) {
            _vm.deleteChildPropertyDialog = false
          },
          submit: _vm.removeChildProperty,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }