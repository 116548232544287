import axios from "axios";
import auth from "./auth";

const HTTP = axios.create({ baseURL: "/" });

const onRequest = (config) => {
  const token = auth.getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

HTTP.interceptors.request.use(onRequest);

export default HTTP;
