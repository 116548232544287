import ListCustomization from "./ListCustomization_List.vue";
import ListCustomization_Form from "./ListCustomization_Form.vue";

const routes = [
  {
    name: "ListCustomization_List",
    path: "customization/lists",
    component: ListCustomization,
  },
  {
    name: "ListCustomization_Form",
    path: "customization/lists/:id",
    component: ListCustomization_Form,
  },
  {
    name: "ListCustomization_Create",
    path: "customization/lists/new",
    component: ListCustomization_Form,
  },
];

export default routes;
