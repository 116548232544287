import { shapes, dia } from "jointjs";
import Event from "@/services/event";
import * as standard from "jointjs/src/shapes/standard.mjs";

// Needed to show diagram correctly
import * as joint from "jointjs";
window.joint = joint;

const _private = {
  graph: null,
  paper: null,
  editCell: null,
  removeAssociation: null,
};

const helper = {
  createGraph,
  increaseHeight,
  graphToJSON,
  graphFromJSON,
  nameIsUsed,
  getEntities,
  getEnums,
  addLink,
  addSuperclassLink,
  refreshGraphCells,
  getPossibleSuperclasses,
  removeSuperclassLink,
  resize,
  zoom,
  checkSuperclassProps,
  checkInheritedClassesProps,
  getEntitiesWithSuperclass,
  removeInheritedDuplicatedProps,
};

export default helper;

function createGraph(paperId, stencilId, editCell, removeAssociation) {
  _createGraph(paperId);
  _createStencil(stencilId);
  _private.editCell = editCell;
  _private.removeAssociation = removeAssociation;
}

function nameIsUsed(formCell) {
  return (
    _private.graph
      .getElements()
      .find(
        (e) => e.id !== formCell.id && e.attributes.name === formCell.name
      ) != null
  );
}

function addLink(params) {
  _private.graph.addCell(new shapes.custom.Association(params));
}

function addSuperclassLink(params) {
  const link = new shapes.custom.Inheritance(params);
  _private.graph.addCell(link);
  return link;
}

function removeSuperclassLink(cellId) {
  _private.graph.getCell(cellId).remove();
}

function graphToJSON() {
  if (!_private.graph) return "";
  return _private.graph.toJSON();
}

function graphFromJSON(json) {
  const cells = JSON.stringify(json);
  _private.graph.clear();
  try {
    _private.graph.fromJSON(JSON.parse(cells));
    _private.paper.setDimensions(maxWidth(), maxHeight());
  } catch (err) {
    console.error("Graph JSON is empty");
    throw err;
  }
}

/**
 * This method checks that the entity has not as superclass the given
 * forbiddenSuperclass and calls to its superclass to check the same recursively
 * @param entity entity on which to verify
 * @param forbiddenSuperclassName name of the forbidden entity
 * @return true if forbiddenSuperclass is not present as superclass of the entity
 * @private
 */
function _checkSuperclass(entity, forbiddenSuperclassName) {
  if (!entity.superclass) {
    return true;
  }
  if (entity.superclass && entity.superclass.name === forbiddenSuperclassName) {
    return false;
  }
  return _checkSuperclass(entity.superclass, forbiddenSuperclassName);
}

function getPossibleSuperclasses(currentEntityName) {
  return getEntities()
    .filter(
      (e) =>
        e.attributes.name !== currentEntityName &&
        _checkSuperclass(e.attributes, currentEntityName)
    )
    .map((e) => {
      return { name: e.attributes.name, superclass: e.attributes.superclass };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
}

/**
 * This method get the entities with the superclass given as parameter
 * @param currentEntityName entity
 * @return list of entities
 */
function getEntitiesWithSuperclass(currentEntityName) {
  return getEntities().filter(
    (e) =>
      e.attributes.superclass &&
      e.attributes.superclass.name === currentEntityName
  );
}

/**
 * This method get the superclass as an entity
 * @param superclassName name of the superclass
 * @return entity
 */
function getSuperclass(superclassName) {
  return getEntities().filter((e) => e.attributes.name === superclassName);
}

/**
 * Checks that the given entity does not have repeated properties in any of its superclasses
 * @param entity : entity that is going to be checked
 * @param superclassName : name of the superclass to be checked
 * @returns {boolean} : true if there is a repeated prop, false otherwise
 */
function checkSuperclassProps(entity, superclassName) {
  const superclass = getSuperclass(superclassName)[0];
  for (const x of superclass.attributes.properties) {
    for (const y of entity.properties) {
      if (x.name === y.name) {
        return true;
      }
    }
  }
  if (!superclass.attributes.superclass) {
    return false;
  } else {
    return checkSuperclassProps(entity, superclass.attributes.superclass.name);
  }
}

/**
 * Checks that the given entity has not repeated attributes in the inherited classes
 * @param entity : entity that is going to be checked
 * @param inheritedClasses : list of inherited classes of the given entity
 * @returns {boolean} : true if there is a repeated property, false otherwise
 */
function checkInheritedClassesProps(entity, inheritedClasses) {
  let _inheritedClasses = [];
  if (!inheritedClasses) {
    inheritedClasses = getEntitiesWithSuperclass(entity.name);
  }
  for (const inheritedClass of inheritedClasses) {
    for (const x of inheritedClass.attributes.properties) {
      for (const y of entity.properties) {
        if (x.name === y.name) {
          return true;
        }
      }
    }
    _inheritedClasses = _inheritedClasses.concat(
      getEntitiesWithSuperclass(inheritedClass.attributes.name)
    );
  }
  if (_inheritedClasses.length > 0) {
    return checkInheritedClassesProps(entity, _inheritedClasses);
  }
  return false;
}

/**
 * Loops through the properties of the given entity and removes all repeated properties on the child entities
 * @param entity : entity with the properties that must not to be repeated
 * @param inheritedClasses : classes that are going to be checked
 */
function removeInheritedDuplicatedProps(entity, inheritedClasses) {
  if (!inheritedClasses) {
    inheritedClasses = getEntitiesWithSuperclass(entity.name);
  }
  let _inheritedClasses = [];
  for (const inheritedClass of inheritedClasses) {
    for (const x of inheritedClass.attributes.properties) {
      for (const y of entity.properties) {
        if (x.name === y.name) {
          inheritedClass.attributes.properties.splice(
            inheritedClass.attributes.properties.indexOf(x),
            1
          );
        }
      }
    }
    _inheritedClasses = _inheritedClasses.concat(
      getEntitiesWithSuperclass(inheritedClass.attributes.name)
    );
  }
  if (_inheritedClasses.length > 0) {
    removeInheritedDuplicatedProps(entity, _inheritedClasses);
  }
}

function getEntities() {
  return _private.graph
    .getElements()
    .filter((e) => e.attributes.type === "custom.Class");
}

function getEnums() {
  return _private.graph
    .getElements()
    .filter((e) => e.attributes.type === "custom.Enum");
}

function _createGraph(paperId) {
  _private.graph = new dia.Graph();
  _private.paper = new dia.Paper({
    cellViewNamespace: { standard },
    el: document.getElementById(paperId),
    model: _private.graph,
    width: maxWidth(),
    height: maxHeight(),
    gridSize: 1,
    restrictTranslate: true,
  });

  // _private.paper.on('cell:mouseenter', (cellView, e, x, y) => {
  //   if (cellView.model.isLink()) return
  //   const offset = {
  //     x: x - cellView.model.position().x,
  //     y: y - cellView.model.position().y
  //   }
  //   document.getElementById('cellMenu').style.left = e.pageX - offset.x
  //   document.getElementById('cellMenu').style.top = e.pageY - offset.y
  // })

  _private.paper.on("cell:pointerdblclick", function (cellView) {
    _private.editCell(cellView.model);
  });

  _private.paper.on("tool:remove", function (cellView) {
    _private.removeAssociation(cellView.model);
  });

  _private.paper.on({
    "cell:pointerdown": function (elementView, evt) {
      evt.data = elementView.model.position();
    },
    "cell:pointerup": function (elementView, evt, x, y) {
      var elementAbove = elementView.model;
      if (
        x < 0 ||
        x > _private.paper.options.width ||
        y < 1 ||
        y > _private.paper.options.height
      )
        elementAbove.position(evt.data.x, evt.data.y);
    },
  });
}

function _createFlyPaperDiv() {
  const d = document.createElement("div");
  d.id = "flyPaper";
  d.style =
    "position: fixed; z-index: 10000; opacity: .7; pointer-event: none;";
  return d;
}

/*
  This method is necessary to refresh the graph. If you do not call this
  method after make a change, the diagram will not be refreshed with the
  new values
*/
function refreshGraphCells() {
  _private.graph.fromJSON(_private.graph.toJSON());
}

function _createStencilElements(graph) {
  new shapes.custom.Class({
    position: { x: 10, y: 1 },
    name: "Class",
    properties: [
      { name: "id", class: "Long", pk: true, displayString: true },
      { name: "aProperty", class: "String" },
    ],
  }).addTo(graph);

  new shapes.custom.Enum({
    position: { x: 170, y: 1 },
    name: "Enum",
    values: ["A_VALUE", "ANOTHER_VALUE"],
  }).addTo(graph);
}

function _adjustOffset(e, offset) {
  document.getElementById("flyPaper").style.left = e.pageX - offset.x + "px";
  document.getElementById("flyPaper").style.top = e.pageY - offset.y + "px";
}

function _createStencil(stencilId) {
  const graph = new dia.Graph();
  const paper = new dia.Paper({
    cellViewNamespace: { standard },
    el: document.getElementById(stencilId),
    model: graph,
    height: 75,
    interactive: false,
  });

  _createStencilElements(graph);

  paper.on("cell:pointerdown", (cellView, e, x, y) => {
    document.body.appendChild(_createFlyPaperDiv());

    const flyGraph = new dia.Graph();
    new dia.Paper({
      cellViewNamespace: { standard },
      el: document.getElementById("flyPaper"),
      width: cellView.el.getBBox().width,
      height: cellView.el.getBBox().height,
      model: flyGraph,
      interactive: false,
    });
    const flyShape = cellView.model.clone();
    const offset = {
      x: x - cellView.model.position().x,
      y: y - cellView.model.position().y,
    };

    flyShape.position(0, 0);
    flyGraph.addCell(flyShape);

    _adjustOffset(e, offset);
    Event.addEventListener("mousemove.fly", (e) => {
      _adjustOffset(e, offset);
    });

    Event.addEventListener("mouseup.fly", (e) => {
      const x = e.pageX;
      const y = e.pageY;
      const target = _private.paper.$el.offset();
      const width = _private.paper.$el.width();
      const height = _private.paper.$el.height();

      if (
        target.left < x &&
        x < target.left + width &&
        target.top < y &&
        y < target.top + height
      ) {
        const s = flyShape.clone();
        s.position(x - target.left - offset.x, y - target.top - offset.y);
        _private.graph.addCell(s);
        _private.editCell(s);
      }

      Event.removeEventListener("mousemove.fly");
      Event.removeEventListener("mouseup.fly");
      document.getElementById("flyPaper").remove();
    });
  });
}

function increaseHeight(increase) {
  let height =
    _private.paper.options.height + increase > 200
      ? _private.paper.options.height + increase
      : 200;
  if (increase < 0) {
    height = height < maxHeight() ? maxHeight() : height;
  }
  _private.paper.setDimensions(_private.paper.options.width, height);
}

function maxHeight() {
  let maxElm = Math.max(
    ..._private.graph
      .getElements()
      .map(
        (el) =>
          el.position().y +
          el.attributes.attrs[".uml-class-name-rect"]?.height +
          (el.attributes.attrs[".uml-class-properties-rect"]?.height ||
            el.attributes.attrs[".uml-class-values-rect"]?.height)
      )
  );
  const pageHeight = document.body.clientHeight - 24 - 99 - 64;
  return maxElm + 4 > pageHeight ? maxElm + 4 : pageHeight;
}
function maxWidth(pageWidth) {
  let maxElm = Math.max(
    ..._private.graph
      .getElements()
      .map(
        (el) =>
          el.position().x +
          el.attributes.attrs[".uml-class-name-rect"]?.width +
          (el.attributes.attrs[".uml-class-properties-rect"]?.width ||
            el.attributes.attrs[".uml-class-values-rect"]?.width)
      )
  );
  return maxElm + 4 > pageWidth ? maxElm + 4 : pageWidth;
}
function resize(width, height) {
  _private.paper.setDimensions(maxWidth(width - 17), maxHeight());
}

function zoom(increase) {
  const scale = _private.paper.scale().sx + increase;
  if (scale > 0.1) {
    _private.paper.scale(scale, scale);
    _private.paper.setDimensions(
      (_private.paper.options.width * scale) / (scale - increase),
      (_private.paper.options.height * scale) / (scale - increase)
    );
  }
}
