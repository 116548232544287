var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        align: "center",
                        justify: "space-between",
                        "no-gutters": "",
                      },
                    },
                    [
                      _c("v-col", [
                        _c("span", { staticClass: "headline" }, [
                          _vm._v(_vm._s(_vm.$t("custom.form.title.form"))),
                        ]),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: { to: { name: "FormCustomization_List" } },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary ml-2",
                              attrs: { disabled: !_vm.validForm },
                              on: { click: () => (_vm.dialog = !_vm.dialog) },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("preview")) + " ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "success ml-2",
                              attrs: { disabled: !_vm.validForm },
                              on: { click: _vm.save },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.validForm,
                        callback: function ($$v) {
                          _vm.validForm = $$v
                        },
                        expression: "validForm",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "6" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          label: _vm.$t("custom.form.formId"),
                                          rules: [
                                            (v) =>
                                              !!v ||
                                              _vm.$t("validation.required"),
                                          ],
                                        },
                                        model: {
                                          value: _vm.formElement.id,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.formElement, "id", $$v)
                                          },
                                          expression: "formElement.id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          dense: "",
                                          items: _vm.entitiesDisp,
                                          "item-text": "name",
                                          label: _vm.$tc("entity.name", 1),
                                          rules: [
                                            (v) =>
                                              !!v ||
                                              _vm.$t("validation.required"),
                                          ],
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.reloadProperties()
                                          },
                                        },
                                        model: {
                                          value: _vm.formElement.entity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formElement,
                                              "entity",
                                              $$v
                                            )
                                          },
                                          expression: "formElement.entity",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "checkbox-dense",
                                        attrs: {
                                          dense: "",
                                          disabled: !_vm.creatableSelected,
                                          label: _vm.$t(
                                            "custom.form.creatable"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateEditable($event)
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            !_vm.creatableSelected
                                              ? {
                                                  key: "append",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        {},
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-help-circle"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            2492510221
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "custom.form.mandatory_feature",
                                                                  {
                                                                    param:
                                                                      _vm.$t(
                                                                        "GUI_F_Creatable.label"
                                                                      ),
                                                                  }
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                }
                                              : null,
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.formElement.creatable,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formElement,
                                              "creatable",
                                              $$v
                                            )
                                          },
                                          expression: "formElement.creatable",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "checkbox-dense",
                                        attrs: {
                                          dense: "",
                                          disabled: !_vm.editableSelected,
                                          label: _vm.$t("custom.form.editable"),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateEditable($event)
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            !_vm.editableSelected
                                              ? {
                                                  key: "append",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        {},
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-help-circle"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            2492510221
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "custom.form.mandatory_feature",
                                                                  {
                                                                    param:
                                                                      _vm.$t(
                                                                        "GUI_F_Editable.label"
                                                                      ),
                                                                  }
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                }
                                              : null,
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.formElement.editable,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formElement,
                                              "editable",
                                              $$v
                                            )
                                          },
                                          expression: "formElement.editable",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "checkbox-dense",
                                        attrs: {
                                          dense: "",
                                          disabled: !_vm.removableSelected,
                                          label: _vm.$t(
                                            "custom.form.removable"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateRemovable()
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            !_vm.removableSelected
                                              ? {
                                                  key: "append",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        {},
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-help-circle"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            2492510221
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "custom.form.mandatory_feature",
                                                                  {
                                                                    param:
                                                                      _vm.$t(
                                                                        "GUI_F_Removable.label"
                                                                      ),
                                                                  }
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                }
                                              : null,
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.formElement.removable,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formElement,
                                              "removable",
                                              $$v
                                            )
                                          },
                                          expression: "formElement.removable",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4", offset: "8" } },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "checkbox-dense",
                                        attrs: {
                                          dense: "",
                                          disabled:
                                            !_vm.formElement.removable ||
                                            !_vm.confirmationAlertSelected,
                                          label: _vm.$t(
                                            "custom.form.confirmRemoval"
                                          ),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            !_vm.formElement.removable ||
                                            !_vm.confirmationAlertSelected
                                              ? {
                                                  key: "append",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        {},
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-help-circle"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            2492510221
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "custom.form.mandatory_feature",
                                                                  {
                                                                    param:
                                                                      _vm.$t(
                                                                        "GUI_F_R_ConfirmationAlert.label"
                                                                      ),
                                                                  }
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                }
                                              : null,
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.formElement.confirmation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formElement,
                                              "confirmation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formElement.confirmation",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          dense: "",
                                          label: _vm.$t(
                                            "custom.form.instructions"
                                          ),
                                        },
                                        model: {
                                          value: _vm.formElement.instructions,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formElement,
                                              "instructions",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formElement.instructions",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider", { attrs: { vertical: "" } }),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "6" } },
                            [
                              _c(
                                "div",
                                { staticClass: "title text-center mb-2" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$tc("property.name", 2)) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("v-data-table", {
                                attrs: {
                                  dense: "",
                                  headers: _vm.headers,
                                  items: _vm.propertiesDisp,
                                  "hide-default-footer": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: `item.editing`,
                                      fn: function ({ item }) {
                                        return [
                                          _c("v-checkbox", {
                                            staticClass: "selected-check",
                                            attrs: {
                                              disabled:
                                                _vm.editingDisabled ||
                                                item.property == "id",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.updatePropEditing(
                                                  item
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.editing,
                                              callback: function ($$v) {
                                                _vm.$set(item, "editing", $$v)
                                              },
                                              expression: "item.editing",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                    {
                                      key: `item.viewing`,
                                      fn: function ({ item }) {
                                        return [
                                          _c("v-checkbox", {
                                            staticClass: "selected-check",
                                            on: {
                                              change: function ($event) {
                                                return _vm.updatePropViewing(
                                                  item
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.viewing,
                                              callback: function ($$v) {
                                                _vm.$set(item, "viewing", $$v)
                                              },
                                              expression: "item.viewing",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("view-selector", {
        attrs: {
          visibility: _vm.formElement.properties,
          visibleProperties: _vm.visibleProperties,
          editableProperties: _vm.editableProperties,
          name: _vm.formElement.id,
          dialog: _vm.dialog,
          editable: _vm.formElement.editable,
          creatable: _vm.formElement.creatable,
          removable: _vm.formElement.removable,
        },
        on: { exit: () => (_vm.dialog = false) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }