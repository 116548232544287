var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("span", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("database.title"))),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("v-text-field", {
                attrs: { label: _vm.$t("database.hostname") },
                model: {
                  value: _vm.innerConfig.host,
                  callback: function ($$v) {
                    _vm.$set(_vm.innerConfig, "host", $$v)
                  },
                  expression: "innerConfig.host",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "2" } },
            [
              _c("v-text-field", {
                attrs: { type: "number", label: _vm.$t("database.port") },
                model: {
                  value: _vm.innerConfig.port,
                  callback: function ($$v) {
                    _vm.$set(_vm.innerConfig, "port", $$v)
                  },
                  expression: "innerConfig.port",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "3" } },
            [
              _c("v-text-field", {
                attrs: { label: _vm.$t("database.database") },
                model: {
                  value: _vm.innerConfig.database,
                  callback: function ($$v) {
                    _vm.$set(_vm.innerConfig, "database", $$v)
                  },
                  expression: "innerConfig.database",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "2" } },
            [
              _c("v-text-field", {
                attrs: { label: _vm.$t("database.username") },
                model: {
                  value: _vm.innerConfig.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.innerConfig, "username", $$v)
                  },
                  expression: "innerConfig.username",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "2" } },
            [
              _c("v-text-field", {
                attrs: { label: _vm.$t("database.password") },
                model: {
                  value: _vm.innerConfig.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.innerConfig, "password", $$v)
                  },
                  expression: "innerConfig.password",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }