<template>
  <v-container>
    <!--
      NOTE: This form has its fields autocomplete disabled'
      For totally disabling autocomplete in form inputs is
      necessary provide a wrong value as autocomplete att value.
      Source: https://developer.mozilla.org/es/docs/Web/Security/Securing_your_site/desactivar_autocompletado_formulario
    -->
    <v-form v-model="validForm" ref="form">
      <v-row dense>
        <!-- Form -->
        <v-col cols="12" md="6">
          <v-text-field
            dense
            v-model="address.line1"
            type="text"
            :label="$t('gc_address.line1')"
            autocomplete="no"
          ></v-text-field>
          <v-text-field
            dense
            v-model="address.line2"
            type="text"
            :label="$t('gc_address.line2')"
            autocomplete="no"
          ></v-text-field>
          <!-- Zipcode -->
          <v-row>
            <v-col cols="6">
              <number-field
                class="zipcode-field"
                type="Long"
                v-model="address.zipCode"
                :label="$t('gc_address.zipcode')"
                autocomplete="no"
              ></number-field>
            </v-col>
            <!-- Country -->
            <v-col cols="6">
              <v-autocomplete
                v-model="address.country"
                :items="countries"
                :label="$t('gc_address.country')"
                @change="(val) => updateData(val, 'subdivisions1')"
                clearable
                autocomplete="no"
              ></v-autocomplete>
            </v-col>
            <!-- Subdivision 1 -->
            <v-col
              cols="6"
              v-if="address.subdivision1 || subdivisions1.length > 0"
            >
              <v-autocomplete
                v-model="address.subdivision1"
                :items="subdivisions1"
                :label="$t('gc_address.subdivision1')"
                @change="(val) => updateData(val, 'subdivisions2')"
                clearable
                autocomplete="no"
              ></v-autocomplete>
            </v-col>
            <!-- Subdivision 2 -->
            <v-col
              cols="6"
              v-if="address.subdivision2 || subdivisions2.length > 0"
            >
              <v-autocomplete
                v-model="address.subdivision2"
                :items="subdivisions2"
                :label="$t('gc_address.subdivision2')"
                @change="(val) => updateData(val, 'subdivisions3')"
                clearable
                autocomplete="no"
              ></v-autocomplete>
            </v-col>
            <!-- Subdivision 3 -->
            <v-col
              cols="6"
              v-if="address.subdivision3 || subdivisions3.length > 0"
            >
              <v-autocomplete
                v-model="address.subdivision3"
                :items="subdivisions3"
                :label="$t('gc_address.subdivision3')"
                @change="(val) => updateData(val, 'subdivisions4')"
                clearable
                autocomplete="no"
              ></v-autocomplete>
            </v-col>
            <!-- Subdivision 4 -->
            <v-col
              cols="6"
              v-if="address.subdivision4 || subdivisions4.length > 0"
            >
              <v-autocomplete
                v-model="address.subdivision4"
                :items="subdivisions4"
                :label="$t('gc_address.subdivision4')"
                @change="(val) => updateData(val, 'towns')"
                clearable
                autocomplete="no"
              ></v-autocomplete>
            </v-col>
            <!-- Town -->
            <v-col cols="6" v-if="address.town || towns.length > 0">
              <v-autocomplete
                v-model="address.town"
                :items="towns"
                :label="$t('gc_address.town')"
                clearable
                autocomplete="no"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-btn
            @click="searchAddress()"
            width="100%"
            :disabled="!address.country"
          >
            {{ $t("gc_address.geolocate") }}
          </v-btn>
          <v-row>
            <v-col cols="6">
              <v-text-field
                type="text"
                v-model="address.point.coordinates[1]"
                :label="$t('gc_address.latitude')"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                type="text"
                v-model="address.point.coordinates[0]"
                :label="$t('gc_address.longitude')"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <!-- Map -->
        <v-col cols="12" md="6">
          <gc-map
            :id="id"
            :ref="id"
            :feature="address.point"
            :geolocation="true"
            @geolocated="fillLatLng"
            @searched="fillAddress"
          ></gc-map>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import NumberField from "@/components/common/number-field/NumberField.vue";
import GCMap from "./GCMap";

const sampleData = {
  countries: ["España"],
  subdivisions1: [
    {
      parent: "España",
      value: "Galicia",
    },
  ],
  subdivisions2: [
    {
      parent: "Galicia",
      value: "A Coruña",
    },
  ],
  subdivisions3: [
    {
      parent: "A Coruña",
      value: "A Coruña",
    },
  ],
  subdivisions4: [],
  towns: [],
};

export default {
  name: "GCAddressForm",
  components: {
    NumberField,
    "gc-map": GCMap,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "address",
    },
  },
  data() {
    return {
      address: {
        line1: null,
        line2: null,
        zipCode: null,
        country: null,
        subdivision1: null,
        subdivision2: null,
        subdivision3: null,
        subdivision4: null,
        town: null,
        point: {
          type: "Point",
          coordinates: [],
        },
      },
      countries: [],
      subdivisions1: [],
      subdivisions2: [],
      subdivisions3: [],
      subdivisions4: [],
      towns: [],
      validForm: true,
    };
  },
  created() {
    sampleData.countries.forEach((country) => this.countries.push(country));
  },
  methods: {
    removeElement(propName) {
      // setting null recursively element and its childs
      switch (propName) {
        case "subdivisions1":
          this.address.subdivision1 = null;
          this.subdivisions1 = [];
          this.removeElement("subdivisions2");
          break;
        case "subdivisions2":
          this.address.subdivision2 = null;
          this.subdivisions2 = [];
          this.removeElement("subdivisions3");
          break;
        case "subdivisions3":
          this.address.subdivision3 = null;
          this.subdivisions3 = [];
          this.removeElement("subdivisions4");
          break;
        case "subdivisions4":
          this.address.subdivision4 = null;
          this.subdivisions4 = [];
          this.removeElement("towns");
          break;
        case "towns":
          this.address.towns = null;
          this.towns = [];
          break;
        default:
          break;
      }
    },
    updateData(value, nextField) {
      // clear next field and its children data
      this.removeElement(nextField);
      // push accurated sample data to next field
      sampleData[nextField]
        .filter((el) => el.parent == value)
        .forEach((el) => this[nextField].push(el.value));
    },
    searchAddress() {
      // call geolocate method from gc-map
      this.$refs[this.id].geolocate(this.address);
    },
    fillLatLng(latLng) {
      this.address.point.coordinates = [];
      this.address.point.coordinates.push(latLng.lng);
      this.address.point.coordinates.push(latLng.lat);
    },
    fillAddress(results, latLng) {
      this._setSearchResults(results); // fill address props with results
      this.fillLatLng(latLng);
    },
    _setSearchResults(results) {
      this.address.line1 = null; //reset first line
      results.forEach((field) => {
        if (field.types.includes("administrative_area_level_5"))
          this.address.town = field.long_name;
        else if (field.types.includes("administrative_area_level_4"))
          this.address.subdivision4 = field.long_name;
        else if (
          field.types.includes("administrative_area_level_3") ||
          field.types.includes("locality")
        )
          this.address.subdivision3 = field.long_name;
        else if (field.types.includes("administrative_area_level_2"))
          this.address.subdivision2 = field.long_name;
        else if (field.types.includes("administrative_area_level_1"))
          this.address.subdivision1 = field.long_name;
        else if (field.types.includes("country"))
          this.address.country = field.long_name;
        else if (field.types.includes("postal_code"))
          this.address.zipCode = field.long_name;
        else
          this.address.line1 = this.address.line1
            ? field.long_name.concat(", " + this.address.line1)
            : field.long_name;
      });
    },
  },
};
</script>

<style scoped>
.zipcode-field {
  padding-top: 18px !important;
}
</style>
