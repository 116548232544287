<template>
  <v-container class="mt-8" fluid>
    <v-form v-model="validForm" ref="form">
      <v-row align="center" justify="center">
        <v-col cols="12" lg="2">
          <v-text-field
            v-model="specificationModified.basicData.name"
            :rules="[(v) => !!v || $t('project.validation.name')]"
            required
            :label="$t('project.project_name')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="2">
          <v-text-field
            v-model="specificationModified.basicData.version"
            :rules="[
              (v) => !!v || $t('project.validation.version'),
              (v) =>
                /^(?!0\.0\.0$)(\d+\.){2}\d+$/.test(v) ||
                $t('project.validation.version_format'),
            ]"
            required
            :label="$t('project.project_version')"
            :hint="$t('project.validation.version_format')"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" class="mt-4">
        <v-col md="3">
          <v-btn block text @click="createProject()" :disabled="!validForm">
            {{ $t("project.button.create") }}
          </v-btn>
          <v-btn block text @click="cancelEditMode()" color="error">
            {{ $t("cancel") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Project from "@/services/project.service";
import store from "@/services/store";
import defaultSpecification from "@/default.json";

// este componente crea nuevo proyecto (desde cero o partir de una spec) o renombra un proyecto existente
export default {
  props: {
    specification: {
      type: Object,
      required: false,
      default() {
        // si no viene specificacion se crea a partir de la por defecto
        // clonándola para evitar modificar props del objeto defaultSpecification
        return JSON.parse(JSON.stringify(defaultSpecification));
      },
    },
    renaming: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      validForm: true,
      // usado para guardar los nombres viejos en caso de renombrar
      specificationModified: JSON.parse(JSON.stringify(this.specification)),
    };
  },
  created() {
    if (this.renaming) {
      store.navigation.pageTitle = this.$t("project.title.renaming");
    } else if (this.specificationModified.basicData.name) {
      store.navigation.pageTitle = this.$t("project.title.import");
    } else {
      store.navigation.pageTitle = this.$t("project.title.create");
    }
  },
  mounted() {
    if (this.specification.basicData.name) {
      this.$refs.form.validate();
    }
  },
  methods: {
    async createProject() {
      try {
        await Project.get(
          this.specificationModified.basicData.name,
          this.specificationModified.basicData.version
        );
        this.$notify({
          type: "error",
          text: this.$t("project.notification.duplicated"),
        });
      } catch (err) {
        if (this.renaming) {
          return this._renameProject();
        } else {
          return this._createProject();
        }
      }
    },
    cancelEditMode() {
      this.$router.go(-1);
    },
    async _createProject() {
      await Project.create(this.specificationModified);
      this.$notify({
        text: this.$t("project.notification.created"),
        type: "success",
      });
      this.$router.push(
        `/${this.specificationModified.basicData.name}/${this.specificationModified.basicData.version}`
      );
    },
    async _renameProject() {
      await Project.update(
        this.specificationModified,
        this.specification.basicData
      );
      this.$notify({
        text: this.$t("project.notification.renamed"),
        type: "success",
      });
      this.$router.push(
        `/${this.specificationModified.basicData.name}/${this.specificationModified.basicData.version}`
      );
    },
  },
};
</script>
