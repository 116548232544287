import Vue from "vue";
import VueRouter from "vue-router";

import auth from "@/services/auth";
import engineStore from "./services/engineStore";

import customizationRouter from "@/components/customization/router.js";
import specificationRouter from "@/components/specification/router.js";
import projectSelectionRouter from "@/components/project_selection/router.js";

import store from "@/services/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
].concat(projectSelectionRouter, specificationRouter, customizationRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  engineStore.init.finally(() => {
    auth.isAuthenticationChecked.finally(() => {
      // por defecto las rutas restringen el acceso a usuario autenticados
      const requiresAuth = !to.meta.public;
      const userIsLogged = auth.userIsLogged();

      if (requiresAuth) {
        if (userIsLogged) {
          next();
        } else {
          // usuario no está logueado
          next("/login");
        }
      } else {
        // página pública
        if (to.meta.isLoginPage) {
          if (userIsLogged) {
            // si estamos logueados no hace falta volver a mostrar el login
            next({ name: "ProjectSelector", replace: true });
          } else {
            // user is not logged and trying to access login
            if (from.matched.length == 0) {
              store.navigation.thereIsPageBeforeLogin = false;
            }
            next();
          }
        } else {
          next();
        }
      }
    });
  });
});

export default router;
