var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("nav-bar", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "v-container",
                  { staticClass: "pl-0", attrs: { fluid: "" } },
                  [
                    _c("span", { staticClass: "font-italic" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.engineStore.basicData.name) +
                          " (v. " +
                          _vm._s(_vm.engineStore.basicData.version) +
                          ") - "
                      ),
                    ]),
                    _c("span", { staticClass: "font-weight-thin" }, [
                      _vm._v(
                        " " +
                          _vm._s(" " + _vm.$t("menu.parametrization_stage")) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "items",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      to: { name: "CustomizationExporter" },
                      text: "",
                      exact: "",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$tc("project.name", 1)) + " ")]
                ),
                _vm.formsSelected
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          to: { name: "FormCustomization_List" },
                          text: "",
                          exact: "",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$tc("custom.form.name", 2)) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.listsSelected
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          to: { name: "ListCustomization_List" },
                          text: "",
                          exact: "",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$tc("custom.list.name", 2)) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }