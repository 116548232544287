import { render, staticRenderFns } from "./ListCustomization_List.vue?vue&type=template&id=dcffe86a&scoped=true"
import script from "./ListCustomization_List.vue?vue&type=script&lang=js"
export * from "./ListCustomization_List.vue?vue&type=script&lang=js"
import style0 from "./ListCustomization_List.vue?vue&type=style&index=0&id=dcffe86a&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcffe86a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dcffe86a')) {
      api.createRecord('dcffe86a', component.options)
    } else {
      api.reload('dcffe86a', component.options)
    }
    module.hot.accept("./ListCustomization_List.vue?vue&type=template&id=dcffe86a&scoped=true", function () {
      api.rerender('dcffe86a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/client/components/customization/list_customization/ListCustomization_List.vue"
export default component.exports